/****************************/
/* Theme name  : Brave      */
/* Author name : Ashok      */
/****************************/
/* Open Sans  */
@import url('http://fonts.googleapis.com/css?family=Open+Sans:300,400italic,400,600');
/* General */
body {
	color: #666666;
	font-size: 13px;
	line-height: 28px;
	background: #fff;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #666;
	font-family: 'Open Sans', sans-serif;
	font-weight: 600;
	margin: 2px 0px;
	padding: 0px;
}
h1 {
	font-size: 30px;
	line-height: 45px;
}
h2 {
	font-size: 25px;
	line-height: 40px;
}
h3 {
	font-size: 22px;
	line-height: 37px;
}
h4 {
	font-size: 18px;
	line-height: 33px;
}
h5 {
	font-size: 15px;
	line-height: 30px;
}
h6 {
	font-size: 13px;
	line-height: 28px;
}
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover{
	color: #666;
}
p {
	margin: 2px 0px;
	padding: 0px;
	margin-bottom:5px;
}
a:hover,
a:active,
a:focus {
	text-decoration: none !important;
	outline: none !important;
}
:focus {
	outline: none !important;
}
::-moz-focus-inner {
	border: 0 !important;
}
::selection {
	color:#fff;
}
::-moz-selection {
	color:#fff;
}
hr {
	margin: 20px 0px;
	padding: 0px;
	border-top: 0px;
	border-bottom: 1px solid #eee;
}
/* Form */
input,
textarea,
select,
.form input,
.form textarea,
.form select {
	box-shadow: none !important;
}
form{
	line-height:20px !important;
}
form label{
	font-weight:400;
}
dl dt,
dl dd{
	font-size:13px;
	line-height:28px;
}
.table td{
	padding:15px 5px;
}
/* Helper classes */
.padd-5 {
	padding: 5px 8px;
}
.padd-10 {
	padding: 10px 13px;
}
.padd-15{
	padding:15px 18px;
}
.bold {
	font-weight: 600;
}
.para-big{
	font-size:17px;
	line-height:32px;
}	
/* Transition */
.transition{
	-webkit-transition: background 1s ease;
	-moz-transition: background 1s ease;
	-o-transition: background 1s ease;
	-ms-transition: background 1s ease;
	transition: background 1s ease;	
}
/* Thumbnail */
.img-thumbnail {
	padding: 2px;
	background: #fff;
	border-radius: 3px;
	box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.15);
}
/* Dropdown menu */
.dropdown a:focus{
	color:#666;
}
.dropdown-menu {
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.05);
	border-radius: 3px !important;
}
.dropdown-menu li a {
	padding: 7px 12px;
}
.dropdown-menu.dropdown-mini {
	min-width: 120px;
}
.dropdown-menu.dropdown-mini li a {
	font-size: 12px;
	padding: 5px 10px;
}
/* Video container */
.video-container{
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	margin-top:5px;
}
.video-container iframe{
	width: 100%;
	height: 100%;
	border: 0;
	position: absolute;
	top:0;
	left:0;
}
/* Well */
.well {
	line-height: 28px;
	border: 0px;
	box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
	background:#fcfcfc;
}
/* Tooltip */
.tooltip .tooltip-inner{
	font-family:"Open Sans", sans-serif;	
}
/* Popover */
.popover{
	border-radius:5px;
	padding:10px 20px;
	box-shadow:0px 3px 10px rgba(0,0,0,0.1);
	border:1px solid rgba(0,0,0,0.06);
}
.popover .popover-title{
	font-size:16px;
	line-height:30px;
	font-weight:600;
	background:#fff;
	padding:0px;
	padding-bottom:5px;
	border-bottom:1px solid #f4f4f4;
}
.popover .popover-content{
	font-size:13px;
	line-height:28px;
	padding:0px;
	padding-top:5px;
	font-family:"Open Sans", sans-serif;
}
/* Blockquote */
blockquote{
	margin:20px 0px;
	padding:15px 20px;
	background:#fdfdfd;
}
blockquote p{
	font-size:14px;
	line-height:29px;
	color:#777;
	font-style:italic;
}
blockquote small{
	margin-top:10px;
}
/* Dropcaps */
.dropcap-1{
	display: block;
	margin-top: 15px;
	margin-right:5px;
	margin-bottom:3px;
	float: left;
	font-size: 400%;
}
.dropcap-2 { 
	display: block;
	float: left;
	font-size: 400%;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	margin-top: 10px;
	margin-right: 10px;
	color: #fff;
	border-radius:100%;
}
.dropcap-3 { 
	display: block;
	float: left;
	font-size: 400%;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	margin-top: 10px;
	margin-right: 10px;
	color: #fff;
}
/* List styles */
.list-1,
.list-2,
.list-3,
.list-4,
.list-5,
.list-6,
.list-7,
.list-8,
.list-9{
	list-style-type:none;
	padding-left:20px;
}
.list-1 li:before{
	content: "\f069"; /* Asterisk */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-2 li:before{
	content: "\f00c"; /* Check */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-3 li:before{
	content: "\f046"; /* Check-Square-o */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-4 li:before{
	content: "\f067"; /* Plus */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-5 li:before{
	content: "\f005"; /* Star */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-6 li:before{
	content: "\f101"; /* Angle Double Right */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-7 li:before{
	content: "\f0a4"; /* hand-o-right */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-8 li:before{
	content: "\f0da"; /* caret-right */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
.list-9 li:before{
	content: "\f138"; /* chevron-circle-right */
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
}
/* Shapes */
/* Square */
.square-1 {
	display:inline-block;
	height: 20px;
	width: 20px;
	line-height: 20px;
	text-align: center;
}	
.square-2 {
	display:inline-block;
	height: 25px;
	width: 25px;
	line-height: 25px;
	text-align: center;
}	
.square-3 {
	display:inline-block;
	height: 35px;
	width: 35px;
	line-height: 35px;
	text-align: center;
}
.square-4 {
	display:inline-block;
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
}
.square-5 {
	display:inline-block;
	height: 70px;
	width: 70px;
	line-height: 70px;
	text-align: center;
}
/* Circle */
.circle-1 {
	display:inline-block;
	height: 20px;
	width: 20px;
	line-height: 20px;
	text-align: center;
	border-radius: 100%;
}
.circle-2 {
	display:inline-block;
	height: 25px;
	width: 25px;
	line-height: 25px;
	text-align: center;
	border-radius: 100%;
}
.circle-3 {
	display:inline-block;
	height: 35px;
	width: 35px;
	line-height: 35px;
	text-align: center;
	border-radius: 100%;
}
.circle-4 {
	display:inline-block;
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	border-radius: 100%;
}
.circle-5 {
	display:inline-block;
	height: 70px;
	width: 70px;
	line-height: 70px;
	text-align: center;
	border-radius: 100%;
}
/* Border radius */
.sharp {
	border-radius: 0px !important;
}
.rounded-1 {
	border-radius: 2px !important;
}
.rounded-2 {
	border-radius: 4px !important;
}
.rounded-3 {
	border-radius: 6px !important;
}
.rounded-4 {
	border-radius: 10px !important;
}
.rounded-5 {
	border-radius: 100% !important;
}
/* Dividers */
.divider-1{
	height:8px;
	background:url("../../img/dividers/1.png") repeat-x;
	background-size:20px 20px;
	margin:20px 0px;
}
.divider-2{
	height:7px;
	background:url("../../img/dividers/2.png") repeat-x;
	background-size:7px 7px;
	margin:20px 0px;
}
.divider-3{
	height:10px;
	background:url("../../img/dividers/3.png") repeat-x;
	background-size:10px 10px;
	margin:20px 0px;
}
.divider-4{
	border-bottom:1px dashed #d4d4d4;
	margin:20px 0px;
}
.divider-5{
	border-bottom:1px dotted #d4d4d4;
	margin:20px 0px;
}
.divider-6{
	border-top:1px solid #d4d4d4;
	border-bottom:1px solid #d4d4d4;
	padding:2px;
	margin:20px 0px;
}
.divider-7{
	border-top:1px dashed #d4d4d4;
	border-bottom:1px dashed #d4d4d4;
	padding:2px;
	margin:20px 0px;
}
.divider-8{
	border-top:1px dotted #d4d4d4;
	border-bottom:1px dotted #d4d4d4;
	padding:2px;
	margin:20px 0px;
}
.divider-9{
	background:#f5f5f5;
	height:6px;
	margin:20px 0px;
}
.divider-10{
	border-top:1px solid #f1f1f1;
	border-bottom:1px solid #e4e4e4;
	background:#f8f8f8;
	box-shadow:0px 2px 5px rgba(0,0,0,0.05);
	padding:2px;
	margin:20px 0px;
}
/* Text color classes */
.white {
	color: #ffffff !important;
}
a.white {
	color: #ffffff !important;
}
a.white:hover,
a.white:focus {
	color: #ffffff !important;
}
.grey {
	color: #eeeeee !important;
}
a.grey {
	color: #eeeeee !important;
}
a.grey:hover,
a.grey:focus {
	color: #d5d5d5 !important;
}
.black {
	color: #666666 !important;
}
a.black {
	color: #666666 !important;
}
a.black:hover,
a.black:focus {
	color: #4d4d4d !important;
}
.red {
	color: #ed5441 !important;
}
a.red {
	color: #ed5441 !important;
}
a.red:hover,
a.red:focus {
	color: #e52d16 !important;
}
.green {
	color: #51d466 !important;
}
a.green {
	color: #51d466 !important;
}
a.green:hover,
a.green:focus {
	color: #30c247 !important;
}
.lblue {
	color: #32c8de !important;
}
a.lblue {
	color: #32c8de !important;
}
a.lblue:hover,
a.lblue:focus {
	color: #1faabe !important;
}
.blue {
	color: #609cec !important;
}
a.blue {
	color: #609cec !important;
}
a.blue:hover,
a.blue:focus {
	color: #3280e7 !important;
}
.orange {
	color: #f8a841 !important;
}
a.orange {
	color: #f8a841 !important;
}
a.orange:hover,
a.orange:focus {
	color: #f69110 !important;
}
.yellow {
	color: #fcd419 !important;
}
a.yellow {
	color: #fcd419 !important;
}
a.yellow:hover,
a.yellow:focus {
	color: #dfb803 !important;
}
.purple {
	color: #cb79e6 !important;
}
a.purple {
	color: #cb79e6 !important;
}
a.purple:hover,
a.purple:focus {
	color: #ba4ede !important;
}
.rose {
	color: #ff61e7 !important;
}
a.rose {
	color: #ff61e7 !important;
}
a.rose:hover,
a.rose:focus {
	color: #ff2edf !important;
}
.brown {
	color: #d08166 !important;
}
a.brown {
	color: #d08166 !important;
}
a.brown:hover,
a.brown:focus {
	color: #c4613f !important;
}
/* Background color classes */
.bg-white {
	background-color: #ffffff !important;
}
.bg-grey {
	background-color: #eeeeee !important;
}
.bg-black {
	background-color: #666666 !important;
}
.bg-red {
	background-color: #ed5441 !important;
}
.bg-green {
	background-color: #51d466 !important;
}
.bg-lblue {
	background-color: #32c8de !important;
}
.bg-blue {
	background-color: #609cec !important;
}
.bg-orange {
	background-color: #f8a841 !important;
}
.bg-yellow {
	background-color: #fcd419 !important;
}
.bg-purple {
	background-color: #cb79e6 !important;
}
.bg-rose {
	background-color: #ff61e7 !important;
}
.bg-brown {
	background-color: #d08166 !important;
}
/* Border class */
/* Border color */
.br-white {
	border: 1px solid #ffffff;
}
.br-grey {
	border: 1px solid #eeeeee;
}
.br-black {
	border: 1px solid #666666;
}
.br-red {
	border: 1px solid #ed5441;
}
.br-green {
	border: 1px solid #51d466;
}
.br-lblue {
	border: 1px solid #32c8de;
}
.br-blue {
	border: 1px solid #609cec;
}
.br-orange{
	border: 1px solid #f8a841;
}
.br-yellow {
	border: 1px solid #fcd419;
}
.br-purple {
	border: 1px solid #cb79e6;
}
.br-rose {
	border: 1px solid #ff61e7;
}
.br-brown {
	border: 1px solid #d08166;
}
/* Border size */
.br-size-1 {
	border-width: 2px !important;
}
.br-size-2 {
	border-width: 4px !important;
}
.br-size-3 {
	border-width: 6px !important;
}
.br-size-4 {
	border-width: 10px !important;
}
.br-size-5 {
	border-width: 15px !important;
}
/* Border style */
.br-style-1 {
	border-style: dotted !important;
}
.br-style-2 {
	border-style: dashed !important;
}
.br-style-3 {
	border-style: double !important;
	border-width:3px !important;
}
/* Border position */
.br-no-left {
	border-left: 0px !important;
}
.br-no-right {
	border-right: 0px !important;
}
.br-no-top {
	border-top: 0px !important;
}
.br-no-bottom {
	border-bottom: 0px !important;
}
/* Button classes */
.btn {
	border-radius: 2px;
	position: relative;
}
.btn.btn-no-border {
	border: 0px !important;
}
/* Round button */
.btn.btn-round {
	width: 60px;
	height: 60px;
	border-radius: 100%;
	font-size: 15px;
	text-align: center;
}
.btn.btn-round i {
	display: block;
	margin: 3px 0px 2px 0px;
}
.btn.btn-round span {
	font-weight: bold;
}
/* Transparent button */
.btn.btn-transparent {
	border: 1px solid #ffffff !important;
	background: transparent !important;
	color: #ffffff !important;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.btn.btn-transparent:hover,
.btn.btn-transparent:focus,
.btn.btn-transparent.active,
.btn.btn-transparent:active {
	background: #ffffff !important;
	color: #808080 !important;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
}
.btn.btn-white {
	background: #ffffff;
	color: #666666;
	border: 1px solid #dddddd;
}
.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white.active,
.btn.btn-white:active {
	background: #f7f7f7;
	color: #666666;
}
.btn.btn-white.btn-outline {
	background: transparent !important;
	border: 1px solid #f7f7f7 !important;
	color: #666666;
}
.btn.btn-grey {
	background: #eeeeee;
	color: #666666;
	border: 1px solid #d5d5d5;
}
.btn.btn-grey:hover,
.btn.btn-grey:focus,
.btn.btn-grey.active,
.btn.btn-grey:active {
	background: #d5d5d5;
	color: #999;
}
.btn.btn-grey.btn-outline {
	background: transparent !important;
	border: 1px solid #d5d5d5 !important;
	color: #666666;
}
.btn.btn-black {
	color: #ffffff;
	background: #666666;
	border: 1px solid #4d4d4d;
}
.btn.btn-black:hover,
.btn.btn-black:focus,
.btn.btn-black.active,
.btn.btn-black:active {
	background: #4d4d4d;
	color: #ffffff;
}
.btn.btn-black.btn-outline {
	background: transparent !important;
	border: 1px solid #4d4d4d !important;
	color: #4d4d4d;
}
.btn.btn-red {
	color: #ffffff;
	background: #ed5441;
	border: 1px solid #e52d16;
}
.btn.btn-red:hover,
.btn.btn-red:focus,
.btn.btn-red.active,
.btn.btn-red:active {
	color: #ffffff;
	background: #e52d16;
}
.btn.btn-red.btn-outline {
	background: transparent !important;
	border: 1px solid #e52d16 !important;
	color: #e52d16;
}
.btn.btn-green {
	color: #ffffff;
	background: #51d466;
	border: 1px solid #30c247;
}
.btn.btn-green:hover,
.btn.btn-green:focus,
.btn.btn-green.active,
.btn.btn-green:active {
	background: #30c247;
	color: #ffffff;
}
.btn.btn-green.btn-outline {
	background: transparent !important;
	border: 1px solid #30c247 !important;
	color: #30c247;
}
.btn.btn-lblue {
	color: #ffffff;
	background: #32c8de;
	border: 1px solid #1faabe;
}
.btn.btn-lblue:hover,
.btn.btn-lblue:focus,
.btn.btn-lblue.active,
.btn.btn-lblue:active {
	background: #1faabe;
	color: #ffffff;
}
.btn.btn-lblue.btn-outline {
	background: transparent !important;
	border: 1px solid #1faabe !important;
	color: #1faabe;
}
.btn.btn-blue {
	color: #ffffff;
	background: #609cec;
	border: 1px solid #3280e7;
}
.btn.btn-blue:hover,
.btn.btn-blue:focus,
.btn.btn-blue.active,
.btn.btn-blue:active {
	background: #3280e7;
	color: #ffffff;
}
.btn.btn-blue.btn-outline {
	background: transparent !important;
	border: 1px solid #3280e7 !important;
	color: #3280e7;
}
.btn.btn-orange {
	color: #ffffff;
	background: #f8a841;
	border: 1px solid #f69110;
}
.btn.btn-orange:hover,
.btn.btn-orange:focus,
.btn.btn-orange.active,
.btn.btn-orange:active {
	background: #f69110;
	color: #ffffff;
}
.btn.btn-orange.btn-outline {
	background: transparent !important;
	border: 1px solid #f69110 !important;
	color: #f69110;
}
.btn.btn-yellow {
	background: #fcd419;
	color: #ffffff;
	border: 1px solid #dfb803;
}
.btn.btn-yellow:hover,
.btn.btn-yellow:focus,
.btn.btn-yellow.active,
.btn.btn-yellow:active {
	background: #dfb803;
	color: #ffffff;
}
.btn.btn-yellow.btn-outline {
	background: transparent !important;
	border: 1px solid #dfb803 !important;
	color: #dfb803;
}
.btn.btn-purple {
	background: #cb79e6;
	color: #ffffff;
	border: 1px solid #ba4ede;
}
.btn.btn-purple:hover,
.btn.btn-purple:focus,
.btn.btn-purple.active,
.btn.btn-purple:active {
	background: #ba4ede;
	color: #ffffff;
}
.btn.btn-purple.btn-outline {
	background: transparent !important;
	border: 1px solid #ba4ede !important;
	color: #ba4ede;
}
.btn.btn-rose {
	background: #ff61e7;
	color: #ffffff;
	border: 1px solid #ff2edf;
}
.btn.btn-rose:hover,
.btn.btn-rose:focus,
.btn.btn-rose.active,
.btn.btn-rose:active {
	background: #ff2edf;
	color: #ffffff;
}
.btn.btn-rose.btn-outline {
	background: transparent !important;
	border: 1px solid #ff2edf !important;
	color: #ff2edf;
}
.btn.btn-brown {
	background: #d08166;
	color: #ffffff;
	border: 1px solid #c4613f;
}
.btn.btn-brown:hover,
.btn.btn-brown:focus,
.btn.btn-brown.active,
.btn.btn-brown:active {
	background: #c4613f;
	color: #ffffff;
}
.btn.btn-brown.btn-outline {
	background: transparent !important;
	border: 1px solid #c4613f !important;
	color: #c4613f;
}
/* Label */
.label {
	border-radius: 2px;
	font-weight: normal;
	padding: 3px 7px;
	font-size: 12px;
	line-height: 18px;
}
/* Label sizes */
.label.label-xs {
	font-size: 10px;
	padding: 1px 5px;
	line-height: 12px;
}
.label.label-sm {
	font-size: 11px;
	padding: 2px 6px;
	line-height: 15px;
}
.label.label-lg {
	font-size: 13px;
	padding: 5px 9px;
	line-height: 23px;
}
/* Label colors */
.label.label-white{
	color: #666666 !important;
	background: #f7f7f7;
}
.label.label-grey {
	color: #666666 !important;
	background: #eeeeee;
}
.label.label-black {
	color: #ffffff;
	background: #666666;
}
.label.label-red {
	color: #ffffff;
	background: #ed5441;
}
.label.label-green {
	color: #ffffff;
	background: #51d466;
}
.label.label-lblue {
	color: #ffffff;
	background: #32c8de;
}
.label.label-blue {
	color: #ffffff;
	background: #609cec;
}
.label.label-orange {
	color: #ffffff;
	background: #f8a841;
}
.label.label-yellow {
	background: #fcd419;
	color: #ffffff;
}
.label.label-purple {
	background: #cb79e6;
	color: #ffffff;
}
.label.label-rose {
	background: #ff61e7;
	color: #ffffff;
}
.label.label-brown {
	background: #d08166;
	color: #ffffff;
}
/* Badges */
.badge {
	min-width: 10px;
	padding: 3px 7px !important;
	font-size: 12px;
	line-height: 12px;
	font-weight: normal;
	display: inline-block;
	color: #fff;
	border-radius: 10px;
}
.badge.badge-white {
	color: #666666;
	background: #ffffff;
}
.badge.badge-grey {
	color: #a2a2a2;
	background: #eeeeee;
}
.badge.badge-black {
	color: #ffffff;
	background: #666666;
}
.badge.badge-red {
	color: #ffffff;
	background: #ed5441;
}
.badge.badge-green {
	color: #ffffff;
	background: #51d466;
}
.badge.badge-lblue {
	color: #ffffff;
	background: #32c8de;
}
.badge.badge-blue {
	color: #ffffff;
	background: #609cec;
}
.badge.badge-orange {
	color: #ffffff;
	background: #f8a841;
}
.badge.badge-yellow {
	color: #ffffff;
	background: #fcd419;
}
.badge.badge-purple {
	color: #ffffff;
	background: #cb79e6;
}
.badge.badge-rose {
	color: #ffffff;
	background: #ff61e7;
}
.badge.badge-brown {
	color: #ffffff;
	background: #d08166;
}
.btn .badge {
	position: absolute;
	top: -13px;
	right: -8px;
}
/* Highlight   */
.highlight-color{
	color: #fff;
	padding: 2px 4px;
}
.highlight-white {
	background: #ffffff;
	color: #666666;
	padding: 2px 4px;
}
.highlight-grey {
	background: #eeeeee;
	color: #666666;
	padding: 2px 4px;
}
.highlight-black {
	background: #666666;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-red {
	background: #ed5441;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-green {
	background: #51d466;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-lblue {
	background: #32c8de;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-blue {
	background: #609cec;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-orange {
	background: #f8a841;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-yellow {
	background: #fcd419;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-purple {
	background: #cb79e6;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-rose {
	background: #ff61e7;
	color: #ffffff;
	padding: 2px 4px;
}
.highlight-brown {
	background: #d08166;
	color: #ffffff;
  padding: 2px 4px;
}
/* Box Shadows */
.box-shadow-block{
	padding:15px 20px;
	border:1px solid #eee;
	background:#fff;
}
.box-shadow-outer {
	position: relative;
	z-index: 1;
}
.box-shadow-outer .box-shadow-1 {
	box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.2);
}
.box-shadow-outer .box-shadow-2 {
	position: relative;
}
.box-shadow-outer .box-shadow-2:before,
.box-shadow-outer .box-shadow-2:after {
	z-index: -1;
	position: absolute;
	content: "";
	bottom: 15px;
	left: 10px;
	width: 50%;
	top: 50%;
	max-width: 300px;
	background: rgba(0, 0, 0, 0.3);
	box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	transform: rotate(-3deg);
}
.box-shadow-outer .box-shadow-2:after {
	-webkit-transform: rotate(3deg);
	-moz-transform: rotate(3deg);
	-o-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	transform: rotate(3deg);
	right: 10px;
	left: auto;
}
.box-shadow-outer .box-shadow-3 {
	position: relative;
}
.box-shadow-outer .box-shadow-3:before {
	z-index: -1;
	position: absolute;
	content: "";
	bottom: 15px;
	left: 10px;
	width: 50%;
	top: 50%;
	max-width: 300px;
	background: rgba(0, 0, 0, 0.25);
	box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
	-webkit-transform: rotate(-3deg);
	-moz-transform: rotate(-3deg);
	-o-transform: rotate(-3deg);
	-ms-transform: rotate(-3deg);
	transform: rotate(-3deg);
}
.box-shadow-outer .box-shadow-4 {
	position: relative;
}
.box-shadow-outer .box-shadow-4:after {
	z-index: -1;
	position: absolute;
	content: "";
	bottom: 15px;
	right: 10px;
	left: auto;
	width: 50%;
	top: 40%;
	max-width: 300px;
	background: rgba(0, 0, 0, 0.2);
	box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
	-webkit-transform: rotate(3deg);
	-moz-transform: rotate(3deg);
	-o-transform: rotate(3deg);
	-ms-transform: rotate(3deg);
	transform: rotate(3deg);
}
.box-shadow-outer .box-shadow-5 {
	position: relative;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2), 0 0 40px rgba(0, 0, 0, 0.05) inset;
}
.box-shadow-outer .box-shadow-5:before,
.box-shadow-outer .box-shadow-5:after {
	content: "";
	position: absolute;
	z-index: -1;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	top: 0;
	bottom: 0;
	left: 10px;
	right: 10px;
}
.box-shadow-outer .box-shadow-5:after {
	right: 10px;
	left: auto;
	-webkit-transform: skew(8deg) rotate(3deg);
	-moz-transform: skew(8deg) rotate(3deg);
	-ms-transform: skew(8deg) rotate(3deg);
	-o-transform: skew(8deg) rotate(3deg);
	transform: skew(8deg) rotate(3deg);
}
.box-shadow-outer .box-shadow-6 {
	position: relative;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.box-shadow-outer .box-shadow-6:before,
.box-shadow-outer .box-shadow-6:after {
	content: "";
	position: absolute;
	z-index: -1;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
	top: 10px;
	bottom: 10px;
	left: 0;
	right: 0;
}
.box-shadow-outer .box-shadow-6:after {
	right: 10px;
	left: auto;
	-webkit-transform: skew(8deg) rotate(3deg);
	-moz-transform: skew(8deg) rotate(3deg);
	-ms-transform: skew(8deg) rotate(3deg);
	-o-transform: skew(8deg) rotate(3deg);
	transform: skew(8deg) rotate(3deg);
}
/* Box shadow alt */
.box-shadow{
	box-shadow:10px 10px 0px 0px rgba(0,0,0,0.15);
}
/* Progress bar */
.progress {
	box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.1);
}
/* Progress bar sizes */
.progress.pb-xs {
	height: 3px !important;
}
.progress.pb-xs .progress-bar {
	line-height: 3px !important;
}
.progress.pb-sm {
	height: 6px !important;
}
.progress.pb-sm .progress-bar {
	line-height: 6px !important;
}
.progress.pb-md {
	height: 10px !important;
}
.progress.pb-md .progress-bar {
	line-height: 10px !important;
}
.progress.pb-lg {
	height: 24px !important;
}
.progress.pb-lg .progress-bar {
	line-height: 24px !important;
}
.progress .progress-bar {
	box-shadow: none;
}
/* Progress bar colors */
.progress .progress-bar.progress-bar-white {
	background: #eeeeee;
}
.progress .progress-bar.progress-bar-grey {
	background: #d5d5d5;
}
.progress .progress-bar.progress-bar-black {
	background: #666666;
}
.progress .progress-bar.progress-bar-red {
	background: #ed5441;
}
.progress .progress-bar.progress-bar-green {
	background: #51d466;
}
.progress .progress-bar.progress-bar-lblue {
	background: #32c8de;
}
.progress .progress-bar.progress-bar-blue {
	background: #609cec;
}
.progress .progress-bar.progress-bar-orange {
	background: #f8a841;
}
.progress .progress-bar.progress-bar-yellow {
	background: #fcd419;
}
.progress .progress-bar.progress-bar-purple {
	background: #cb79e6;
}
.progress .progress-bar.progress-bar-rose {
	background: #ff61e7;
}
.progress .progress-bar.progress-bar-brown {
	background: #d08166;
}
/* Nav tabs */
.tab-content {
	padding-top: 10px;
}
/* Nav tabs style #1 */
.nav-tabs-one ul.nav-tabs  li{
	margin-right:2px;
}
.nav-tabs-one ul.nav-tabs  li a{
	background:#fcfcfc;
	border:1px solid #eee;
}
.nav-tabs-one .tab-content{
	border:1px solid #eee;
	border-top:0px;
	padding:10px 15px;
	border-bottom-left-radius:3px;
	border-bottom-right-radius:3px;
}
/* Nav tabs style #2 */
.nav-tabs-two ul.nav-tabs  li{
	margin-right:2px;
}
.nav-tabs-two ul.nav-tabs  li a{
	background:#fcfcfc;
	border:1px solid #eee;
	border-bottom:1px solid #fcfcfc;
	padding:12px 15px 10px;
}
.nav-tabs-two ul.nav-tabs  li a:hover{
	background:#fff;
	color:#555;
	border-bottom:1px solid #eee;
	border-left:1px solid #eee;
	border-right:1px solid #eee;
}
.nav-tabs-two ul.nav-tabs li.active a{
	background:#fff;
	color:#555;
	border-bottom:1px solid #fff;
	border-left:1px solid #eee;
	border-right:1px solid #eee;
}
.nav-tabs-two ul.nav-tabs li.active a:hover{
	border-bottom:1px solid #fff;
	border-left:1px solid #eee;
	border-right:1px solid #eee;
}
.nav-tabs-two ul.nav-tabs li.open a{
	background:#fff;
	color:#555;
	border-bottom:1px solid #fff;
	border-left:1px solid #eee;
	border-right:1px solid #eee;
}
.nav-tabs-two ul.nav-tabs li.open a:hover{
	border-bottom:1px solid #fff;
	border-left:1px solid #eee;
	border-right:1px solid #eee;
}
.nav-tabs-two ul.nav-tabs li.open a:focus{
	border-bottom:1px solid #fff;
	border-left:1px solid #eee;
	border-right:1px solid #eee;
}
.nav-tabs-two .tab-content{
	border:1px solid #eee;
	border-top:0px;
	padding:10px 15px;
	border-bottom-left-radius:3px;
	border-bottom-right-radius:3px;
}
/* Nav tabs style 3 */
.nav-tabs-three .nav-tabs-three-sidebar {
	width:230px;
	float:left;
}
.nav-tabs-three .nav-tabs-three-sidebar ul{
	padding:0px;
	margin:0px;
}
.nav-tabs-three .nav-tabs-three-content{
	margin-left:240px;
}
@media (max-width: 767px){
	.nav-tabs-three{
		max-width:400px;
		margin:10px auto;
	}
	.nav-tabs-three .nav-tabs-three-sidebar{
		width:100%;
		float:none;
		position:static;
	}
	.nav-tabs-three .nav-tabs-three-content{
		margin-left:0px !important;
		margin-top:20px;
	}
}
.nav-tabs-three ul.nav li a{
	font-size:13px;
	padding:8px 12px;
	background:#fcfcfc;
	border:1px solid #eee;
	border-radius:3px;
	margin-bottom:5px;
	color:#555;
}
.nav-tabs-three ul.nav  li:hover a{
	border:1px solid;
	color:#fff;
}
.nav-tabs-three ul.nav li.active a{
	border:1px solid;
	color:#fff;
}
.nav-tabs-three .tab-content{
	border:1px solid #eee;
	padding:10px 15px;
	border-radius:3px;
}
/* Panels */
.panel .panel-heading h4{
	font-weight:400;
}
.panel.panel-white {
	border-color: #eeeeee;
}
.panel.panel-white .panel-heading {
	background: #f7f7f7;
	border-color: #eeeeee;
	color: #666666;
}
.panel.panel-white .panel-heading h4 a{
	color:#888;
}
.panel.panel-white .panel-heading h4 a:hover{
	color:#666;
}
.panel.panel-white .panel-footer {
	background: #f7f7f7;
	border-color: #eeeeee;
	color: #666666;
}
.panel.panel-grey {
	border-color: #d5d5d5;
}
.panel.panel-grey .panel-heading {
	background: #eeeeee;
	border-color: #d5d5d5;
	color: #666666;
}
.panel.panel-grey .panel-heading h4 a{
	color:#888;
}
.panel.panel-grey .panel-heading h4 a:hover{
	color:#666;
}
.panel.panel-grey .panel-footer {
	background: #eeeeee;
	border-color: #d5d5d5;
	color: #666666;
}
.panel.panel-black {
	border-color: #4d4d4d;
}
.panel.panel-black .panel-heading {
	background: #666666;
	border-color: #4d4d4d;
	color: #ffffff;
}
.panel.panel-black .panel-heading h4 a{
	color:#fff;
}
.panel.panel-black .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-black .panel-footer {
	background: #666666;
	border-color: #4d4d4d;
	color: #ffffff;
}
.panel.panel-red {
	border-color: #e52d16;
}
.panel.panel-red .panel-heading {
	background: #ed5441;
	border-color: #e52d16;
	color: #ffffff;
}
.panel.panel-red .panel-heading h4 a{
	color:#fff;
}
.panel.panel-red .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-red .panel-footer {
	background: #ed5441;
	border-color: #e52d16;
	color: #ffffff;
}
.panel.panel-green {
	border-color: #30c247;
}
.panel.panel-green .panel-heading {
	background: #51d466;
	border-color: #30c247;
	color: #ffffff;
}
.panel.panel-green .panel-heading h4 a{
	color:#fff;
}
.panel.panel-green .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-green .panel-footer {
	background: #51d466;
	border-color: #30c247;
	color: #ffffff;
}
.panel.panel-lblue {
	border-color: #1faabe;
}
.panel.panel-lblue .panel-heading {
	background: #32c8de;
	border-color: #1faabe;
	color: #ffffff;
}
.panel.panel-lblue .panel-heading h4 a{
	color:#fff;
}
.panel.panel-lblue .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-lblue .panel-footer {
	background: #32c8de;
	border-color: #1faabe;
	color: #ffffff;
}
.panel.panel-blue {
	border-color: #3280e7;
}
.panel.panel-blue .panel-heading {
	background: #609cec;
	border-color: #3280e7;
	color: #ffffff;
}
.panel.panel-blue .panel-heading h4 a{
	color:#fff;
}
.panel.panel-blue .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-blue .panel-footer {
	background: #609cec;
	border-color: #3280e7;
	color: #ffffff;
}
.panel.panel-orange {
	border-color: #f69110;
}
.panel.panel-orange .panel-heading {
	background: #f8a841;
	border-color: #f69110;
	color: #ffffff;
}
.panel.panel-orange .panel-heading h4 a{
	color:#fff;
}
.panel.panel-orange .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-orange .panel-footer {
	background: #f8a841;
	border-color: #f69110;
	color: #ffffff;
}
.panel.panel-yellow {
	border-color: #dfb803;
}
.panel.panel-yellow .panel-heading {
	background: #fcd419;
	border-color: #dfb803;
	color: #ffffff;
}
.panel.panel-yellow .panel-heading h4 a{
	color:#fff;
}
.panel.panel-yellow .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-yellow .panel-footer {
	background: #fcd419;
	border-color: #dfb803;
	color: #ffffff;
}
.panel.panel-purple {
	border-color: #ba4ede;
}
.panel.panel-purple .panel-heading {
	background: #cb79e6;
	border-color: #ba4ede;
	color: #ffffff;
}
.panel.panel-purple .panel-heading h4 a{
	color:#fff;
}
.panel.panel-purple .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-purple .panel-footer {
	background: #cb79e6;
	border-color: #ba4ede;
	color: #ffffff;
}
.panel.panel-rose {
	border-color: #ff2edf;
}
.panel.panel-rose .panel-heading {
	background: #ff61e7;
	border-color: #ff2edf;
	color: #ffffff;
}
.panel.panel-rose .panel-heading h4 a{
	color:#fff;
}
.panel.panel-rose .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-rose .panel-footer {
	background: #ff61e7;
	border-color: #ff2edf;
	color: #ffffff;
}
.panel.panel-brown {
	border-color: #c4613f;
}
.panel.panel-brown .panel-heading {
	background: #d08166;
	border-color: #c4613f;
	color: #ffffff;
}
.panel.panel-brown .panel-heading h4 a{
	color:#fff;
}
.panel.panel-brown .panel-heading h4 a:hover{
	color:#fff;
}
.panel.panel-brown .panel-footer {
	background: #d08166;
	border-color: #c4613f;
	color: #ffffff;
}
/* Accordion */
.accordion-alt3 .panel-heading {
	padding:5px 0px !important;
}
.accordion-alt3 .panel-heading h4{
	font-size:13px;
	line-height:28px;
}
.accordion-alt3 .panel-heading h4 a i{
	font-size:13px;
	line-height:18px;
	width:18px;
	height:18px;
	margin-right:5px;
	color:#fff;
	text-align:center;
}
.accordion-alt3 .panel-body{
	padding:15px 0px;
}
/* Brand & Social Media */
/* Brand with background color */
.brand-bg a i {
	display: inline-block;
	text-decoration: none;
	text-align: center;
	margin-right: 5px;
	color: #fff;
	font-size:14px;
	-webkit-transition: background 2s ease;
	-moz-transition: background 2s ease;
	-o-transition: background 2s ease;
	-ms-transition: background 2s ease;
	transition: background 2s ease;
}
.brand-bg a i:hover {
	-webkit-transition: background 2s ease;
	-moz-transition: background 2s ease;
	-o-transition: background 2s ease;
	-ms-transition: background 2s ease;
	transition: background 2s ease;
}
.brand-bg a.facebook i {
	background: #3280e7;
}
.brand-bg a.facebook i:hover {
	background: #134fa0;
}
.brand-bg a.twitter i {
	background: #32c8de;
}
.brand-bg a.twitter i:hover {
	background: #188392;
}
.brand-bg a.google-plus i {
	background: #f96f4a;
}
.brand-bg a.google-plus i:hover {
	background: #eb6440;
}
.brand-bg a.linkedin i {
	background: #729fda;
}
.brand-bg a.linkedin i:hover {
	background: #3069b6;
}
.brand-bg a.pinterest i {
	background: #ed5441;
}
.brand-bg a.pinterest i:hover {
	background: #b72411;
}
.brand-bg a.dropbox i {
	background: #32c8de;
}
.brand-bg a.dropbox i:hover {
	background: #188392;
}
.brand-bg a.bitcoin i {
	background: #f8a841;
}
.brand-bg a.bitcoin i:hover {
	background: #cc7607;
}
.brand-bg a.foursquare i {
	background: #22bed4;
}
.brand-bg a.foursquare i:hover {
	background: #146f7c;
}
.brand-bg a.flickr i {
	background: #ff61e7;
}
.brand-bg a.flickr i:hover {
	background: #fa00d4;
}
.brand-bg a.github i {
	background: #666666;
}
.brand-bg a.github i:hover {
	background: #333333;
}
.brand-bg a.instagram i {
	background: #d6917a;
}
.brand-bg a.instagram i:hover {
	background: #b35637;
}
.brand-bg a.skype i {
	background: #32c8de;
}
.brand-bg a.skype i:hover {
	background: #188392;
}
.brand-bg a.tumblr i {
	background: #84a1c8;
}
.brand-bg a.tumblr i:hover {
	background: #476d9f;
}
.brand-bg a.vimeo i {
	background: #32c8de;
}
.brand-bg a.vimeo i:hover {
	background: #188392;
}
.brand-bg a.dribbble i {
	background: #ff2edf;
}
.brand-bg a.dribbble i:hover {
	background: #c700a9;
}
.brand-bg a.youtube i {
	background: #ed5441;
}
.brand-bg a.youtube i:hover {
	background: #b72411;
}
/* Brand without background color */
.brand a i {
	display: inline-block;
	text-decoration: none;
	text-align: center;
	margin-right: 10px;
	color: #fff;
	font-size:14px;
	-webkit-transition: color 2s ease;
	-moz-transition: color 2s ease;
	-o-transition: color 2s ease;
	-ms-transition: color 2s ease;
	transition: color 2s ease;
}
.brand a i:hover {
	-webkit-transition: color 2s ease;
	-moz-transition: color 2s ease;
	-o-transition: color 2s ease;
	-ms-transition: color 2s ease;
	transition: color 2s ease;
}
.brand a.facebook i {
	color: #3280e7;
}
.brand a.facebook i:hover {
	color: #134fa0;
}
.brand a.twitter i {
	color: #32c8de;
}
.brand a.twitter i:hover {
	color: #188392;
}
.brand a.google-plus i {
	color: #f96f4a;
}
.brand a.google-plus i:hover {
	color: #eb6440;
}
.brand a.linkedin i {
	color: #729fda;
}
.brand a.linkedin i:hover {
	color: #3069b6;
}
.brand a.pinterest i {
	color: #ed5441;
}
.brand a.pinterest i:hover {
	color: #b72411;
}
.brand a.dropbox i {
	color: #32c8de;
}
.brand a.dropbox i:hover {
	color: #188392;
}
.brand a.bitcoin i {
	color: #f8a841;
}
.brand a.bitcoin i:hover {
	color: #cc7607;
}
.brand a.foursquare i {
	color: #22bed4;
}
.brand a.foursquare i:hover {
	color: #146f7c;
}
.brand a.flickr i {
	color: #ff61e7;
}
.brand a.flickr i:hover {
	color: #fa00d4;
}
.brand a.github i {
	color: #666666;
}
.brand a.github i:hover {
	color: #333333;
}
.brand a.instagram i {
	color: #d6917a;
}
.brand a.instagram i:hover {
	color: #b35637;
}
.brand a.skype i {
	color: #32c8de;
}
.brand a.skype i:hover {
	color: #188392;
}
.brand a.tumblr i {
	color: #84a1c8;
}
.brand a.tumblr i:hover {
	color: #476d9f;
}
.brand a.vimeo i {
	color: #32c8de;
}
.brand a.vimeo i:hover {
	color: #188392;
}
.brand a.dribbble i {
	color: #ff2edf;
}
.brand a.dribbble i:hover {
	color: #c700a9;
}
.brand a.youtube i {
	color: #ed5441;
}
.brand a.youtube i:hover {
	color: #b72411;
}
/* Owl carousel */
.owl-theme .owl-controls{
	margin-top: 10px;
}
.owl-theme .owl-controls .owl-buttons div{
	color: #FFF;
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
	margin: 5px;
	padding: 3px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background: #869791;
	filter: Alpha(Opacity=50);/*IE7 fix*/
	opacity: 0.5;
}
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
	filter: Alpha(Opacity=100);/*IE7 fix*/
	opacity: 1;
	text-decoration: none;
}
.owl-theme .owl-controls .owl-page{
	display: inline-block;
	zoom: 1;
	*display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-pagination{
	text-align: center;
}
.owl-theme .owl-controls .owl-page span{
	display: block;
	width: 12px;
	height: 12px;
	margin: 5px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background: #fff;
	box-shadow:0px 0px 1px rgba(0,0,0,0.4);
}
.owl-theme .owl-controls .owl-page span.owl-numbers{
	height: auto;
	width: auto;
	color: #FFF;
	padding: 0px 10px;
	font-size: 12px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}
.owl-nav{
	text-align:right;
	margin-bottom:10px;
}
.owl-nav a i{
	width:23px;
	height:23px;
	line-height:23px;
	background:#f3f3f3;
	border-bottom:1px solid #ccc;
	color:#ccc;
	display:inline-block;
	border-radius:2px;
	font-size:12px;
	text-align:center;
	cursor:pointer;
}
.owl-nav a i:hover{
	color:#fff;
}
.owl-nav a.owl-nav-prev i{
	margin-right:3px;
}
.owl-carousel .owl-content{
	margin:10px 5px;
}
/* Body Boxed */
body.boxed {
	margin: 0px 80px;
	background: #ffffff url("../../img/body/pattern/1.png") repeat;
}
@media (max-width: 1000px) {
	body.boxed {
		margin: 0px 40px;
	}
}
@media (max-width: 480px) {
	body.boxed {
		margin: 0px 20px;
	}
}
body.boxed .outer {
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
body.boxed .container {
  width: 100%;
}
body.boxed.pattern-1 {
	background: #ffffff url("../../img/body/pattern/1.png") repeat;
}
body.boxed.pattern-2 {
	background: #ffffff url("../../img/body/pattern/2.png") repeat;
}
body.boxed.pattern-3 {
	background: #ffffff url("../../img/body/pattern/3.png") repeat;
}
body.boxed.pattern-4 {
	background: #ffffff url("../../img/body/pattern/4.png") repeat;
}
body.boxed.pattern-5 {
	background: #ffffff url("../../img/body/pattern/5.png") repeat;
}
body.boxed.img-1 {
	background: #ffffff url("../../img/body/img/1.jpg") repeat;
	background-attachment: fixed;
	background-size: cover;
}
body.boxed.img-2 {
	background: #ffffff url("../../img/body/img/2.jpg") repeat;
	background-attachment: fixed;
	background-size: cover;
}
body.boxed.img-3 {
	background: #ffffff url("../../img/body/img/3.jpg") repeat;
	background-attachment: fixed;
	background-size: cover;
}
body.boxed.img-4 {
	background: #ffffff url("../../img/body/img/4.jpg") repeat;
	background-attachment: fixed;
	background-size: cover;
}
body.boxed.img-5 {
	background: #ffffff url("../../img/body/img/5.jpg") repeat;
	background-attachment: fixed;
	background-size: cover;
}
.outer {
	background: #fff;
}
.outer .container {
	width:100%;
	max-width: 1150px;
}
/* Top bar */
.top-bar {
	background: #fdfdfd;
	color: #999;
	padding: 6px 0px;
	font-size: 13px;
	line-height: 25px;
	border-bottom: 1px solid #f5f5f5;
}
.top-bar a {
	color: #999;
}
@media (max-width: 785px) {
	.top-bar .tb-contact {
		display: none;
	}
}
.top-bar .tb-search {
	position: relative;
	display:none;
}
.top-bar .tb-search .b-dropdown-block {
	padding: 7px;
	border: 1px solid #ddd;
	width: 250px;
	position: absolute;
	background: #ffffff;
	top: 30px;
	left: 0px;
	border-radius: 2px;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	display: none;
	z-index: 10000;
}
@media (max-width: 785px) {
	.top-bar .tb-search {
		display: block;
	}
}
.top-bar .tb-social {
	margin-right: 10px;
}
.top-bar .tb-social i {
	font-size: 13px;
	margin: 0px;
	margin-right: 2px;
}
@media (max-width:785px){
	.top-bar .tb-social{
		display:none;
	}
}
.top-bar .tb-shopping-cart {
	position: relative;
	margin-left: 15px;
}
.top-bar .tb-shopping-cart i {
	font-size: 14px;
}
.top-bar .tb-shopping-cart .b-dropdown-block {
	width: 250px;
	background: #ffffff;
	right: 0px;
	border: 1px solid #e2e2e2;
	position: absolute;
	margin-top: 5px;
	z-index: 10000;
	padding: 10px 10px;
	border-radius: 5px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	display: none;
}
.top-bar .tb-shopping-cart .b-dropdown-block h4 {
	font-size: 13px;
	font-weight: 600;
	margin-top: 0px;
	padding-top: 0px;
	border-bottom: 1px dashed #eee;
}
.top-bar .tb-shopping-cart .b-dropdown-block ul li {
	padding: 10px 0px;
	border-bottom: 1px dashed #eee;
}
.top-bar .tb-shopping-cart .cart-img {
	width: 40px;
	float: left;
}
.top-bar .tb-shopping-cart .cart-title {
	position: relative;
	margin-left: 50px;
}
.top-bar .tb-shopping-cart .cart-title h5 {
	font-size: 13px;
	line-height: 23px;
	font-weight:400;
}
.top-bar .tb-shopping-cart .cart-title h5 a {
	color: #666666;
}
.top-bar .tb-shopping-cart .cart-title h5 a:hover {
	color: #808080;
}
.top-bar .tb-language {
	margin-left: 10px;
}
.top-bar .tb-language a {
	color: #888;
}
.top-bar .tb-language a:hover {
	color: #666;
}
/* Header One 	   */
/* Logo            */
/* Navigation menu */
.header-1 {
	padding: 15px 0px 0px 0px;
}
.header-1 .logo {
	margin: 10px 0px 25px 0px;
}
.header-1 .logo h1 {
	font-weight: 600;
}
.header-1 .logo h1 a {
	color: #808080;
}
.header-1 .logo h1 a:hover {
	color: #666666;
}
@media (max-width: 767px) {
	.header-1 .logo h1 {
		text-align: center;
	}
}
.header-1 .header-search {
	max-width: 250px;
	margin: 17px auto;
}
/* Header 1 Navigation menu */
.header-1 .navy ul,
.header-1 .navy ul li,
.header-1 .navy ul li a,
.header-1 .navy #menu-button {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	display: block;
	position: relative;
	z-index:10;
}
.header-1 .navy #menu-button {
	display: none;
}
.header-1 .navy {
	width: auto;
	background: #fff;
}
.header-1 .navy > ul > li {
	float: left;
}
.header-1 .navy > ul > li > a {
	padding: 10px 15px;
	font-size: 13px;
	line-height:28px;
	color: #fff;
	font-weight: 600;
}
.header-1 .navy > ul > li:hover > a,
.header-1 .navy > ul > li > a:hover {
	color: #fff;
}
.header-1 .navy ul > li.has-sub > a:after {
	content: "\f107";
	font-family:"FontAwesome";
	margin-left:6px;
	font-weight:400;
	font-size:14px;
}
.header-1 .navy ul ul {
	position: absolute;
	left: -9999px;
	opacity: 0;
	transition: bottom .4s ease, opacity .3s ease;
}
.header-1 .navy > ul > li > ul {
	top: 50px;
	background:#fff;
	padding:5px 0px;
	box-shadow:0px 5px 15px rgba(0,0,0,0.15);
}
.header-1 .navy > ul > li:hover > ul {
	left: auto;
	top: 45px;
	opacity: 1;
}
.header-1 .navy ul ul ul {
	top: 50px;
	padding:5px 0;
	box-shadow:0px 5px 15px rgba(0,0,0,0.15);
}
.header-1 .navy ul ul > li:hover > ul {
	top: 0;
	left: 170px;
	opacity: 1;
	background:#fff;
}
.header-1 .navy ul ul li a {
	width: 170px;
	padding: 4px 20px;
	font-size: 12px;
	font-weight: 400;
	color: #666;
}
.header-1 .navy ul ul li:hover > a,
.header-1 .navy ul ul li > a:hover{
	color: #888;
}
.header-1 .navy ul ul > li.has-sub > a:after {
	content: "\f105";
	font-family:"FontAwesome";
	margin-left:7px;
	font-weight:400;
	float:right;
	font-size:14px;
}
@media (max-width:785px){
	.header-1 .navi{
		background:transparent !important;
		border-bottom:0px !important;
	}
	.header-1 .navy {
		max-width:400px;
		margin:0px auto;
		margin-bottom:20px;
	}
	.header-1 .navy ul,
	.header-1 .navy ul ul,
	.header-1 .navy ul ul ul,
	.header-1 .navy > ul,
	.header-1 .navy > ul > li > ul,
	.header-1 .navy > ul > li:hover > ul,
	.header-1 .navy ul ul li:hover > ul,
	.header-1 .navy ul ul ul li:hover > ul{
		position: relative;
		left: 0;
		right: auto;
		top: 0;
		width: 100%;
		display: none;
		padding: 0;
		opacity: 1;
		text-align: left;
		box-shadow:none;
	}
	.header-1 .navy > ul > li{
		border:1px solid #eee;
		border-bottom:0px;
	}
	.header-1 .navy > ul > li > a {
		color: #666;
	}
	.header-1 .navy > ul > li:hover > a,
	.header-1 .navy > ul > li > a:hover {
		color: #777;
	}
	.header-1 .navy ul li {
		width: 100%;
		border-top: 1px solid #eee;
	}
	.header-1 .navy ul li:last-child{
		border-bottom:1px solid #eee;
	}
	.header-1 .navy > ul > li > ul,
	.header-1 .navy ul ul ul {
		border-top:1px solid #eee !important;
		border-bottom:1px solid #eee !important;
	}
	.header-1 .navy ul li a {
		padding: 10px 20px;
	}
	.header-1 .navy ul ul li a {
		width:100%;
		padding: 10px 20px 10px 35px;
	}
	.header-1 .navy ul ul ul li a {
		padding: 10px 20px 10px 50px;
	}
	.header-1 .navy ul ul ul ul li a {
		padding: 10px 20px 10px 65px;
	}
	.header-1 .navy > ul > li.has-sub > a:after,
	.header-1 .navy ul ul li.has-sub > a:after {
		display: none;
	}	
	.header-1 .navy #menu-button {
		position: relative;
		display: block;
		padding: 10px 20px;
		cursor: pointer;
		font-size: 13px;
		color: #fff;
		font-weight: 600;
		text-transform: uppercase;
	}
	.header-1 .navy #menu-button:after{
		content: "\f0c9";
		font-family:"FontAwesome";
		margin-left:7px;
		font-weight:400;
		float:right;
		font-size:14px;		
	}
	.header-1 .navy .submenu-button {
		position: absolute;
		right: 0;
		display: block;
		width: 50px;
		height: 50px;
		z-index: 12;
		cursor: pointer;
		border-left:1px solid #eee;
	}
	.header-1 .navy ul ul .submenu-button {
		height: 50px;
	}
	.header-1 .navy ul .submenu-button:after {
		content: "\f107";
		font-family:"FontAwesome";
		font-weight:400;
		font-size:14px;
		line-height:50px;
		text-align:center;
		display: block;
	}
}
@media (min-width:786px){
	.header-1 .navy ul{
		display:block !important;
	}
}
/* Header two 	   */
/* Logo            */
/* Navigation menu */
.header-2 {
	padding: 15px 0px;
}
.header-2 .logo {
	margin: 10px 0px 25px 0px;
}
.header-2 .logo h1 {
	font-weight: 600;
}
.header-2 .logo h1 a {
	color: #808080;
}
.header-2 .logo h1 a:hover {
	color: #666666;
}
@media (max-width: 785px) {
	.header-2 .logo h1 {
		text-align: center;
	}
}
/* Header 2 Navigation menu */
.header-2 .navy ul,
.header-2 .navy ul li,
.header-2 .navy ul li a,
.header-2 .navy #menu-button {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	display: block;
	position: relative;
	z-index:10;
}
.header-2 .navy #menu-button {
	display: none;
}
.header-2 .navy {
	width: auto;
	background: #fff;
}
.header-2 .navy > ul > li {
	float: left;
}
.header-2 .navy > ul > li > a {
	padding: 20px 15px;
	font-size: 13px;
	line-height:28px;
	color: #666;
	font-weight: 600;
}
.header-2 .navy > ul > li:hover > a,
.header-2 .navy > ul > li > a:hover {
	color: #777;
}
.header-2 .navy ul > li.has-sub > a:after {
	content: "\f107";
	font-family:"FontAwesome";
	margin-left:5px;
	font-weight:400;
}
.header-2 .navy ul ul {
	position: absolute;
	left: -9999px;
	opacity: 0;
	transition: bottom .4s ease, opacity .3s ease;
}
.header-2 .navy > ul > li > ul {
	top: 100px;
	background:#fff;
	padding:5px 0px;
	box-shadow:0px 5px 15px rgba(0,0,0,0.15);
}
.header-2 .navy > ul > li:hover > ul {
	left: auto;
	top: 60px;
	opacity: 1;
}
.header-2 .navy ul ul ul {
	top: 50px;
	padding:5px 0;
	box-shadow:0px 5px 15px rgba(0,0,0,0.15);
}
.header-2 .navy ul ul > li:hover > ul {
	top: 0;
	left: 170px;
	opacity: 1;
	background:#fff;
}
.header-2 .navy ul ul li a {
	width: 170px;
	padding: 4px 20px;
	font-size: 12px;
	font-weight: 400;
	color: #666;
}
.header-2 .navy ul ul li:hover > a,
.header-2 .navy ul ul li > a:hover{
	color: #888;
}
.header-2 .navy ul ul > li.has-sub > a:after {
	content: "\f105";
	font-family:"FontAwesome";
	margin-left:7px;
	font-weight:400;
	float:right;
	font-size:14px;
}
@media (max-width:785px){
	.header-2 .navy {
		max-width:400px;
		margin:0px auto;
		margin-bottom:10px;
	}
	.header-2 .navy ul,
	.header-2 .navy ul ul,
	.header-2 .navy ul ul ul,
	.header-2 .navy > ul,
	.header-2 .navy > ul > li > ul,
	.header-2 .navy > ul > li:hover > ul,
	.header-2 .navy ul ul li:hover > ul,
	.header-2 .navy ul ul ul li:hover > ul{
		position: relative;
		left: 0;
		right: auto;
		top: 0;
		width: 100%;
		display: none;
		padding: 0;
		opacity: 1;
		text-align: left;
		box-shadow:none;
	}
	.header-2 .navy > ul > li{
		border:1px solid #eee;
		border-bottom:0px;
	}
	.header-2 .navy ul li {
		width: 100%;
		border-top: 1px solid #eee;
	}
	.header-2 .navy ul li:last-child{
		border-bottom:1px solid #eee;
	}
	.header-2 .navy > ul > li > ul,
	.header-2 .navy ul ul ul {
		border-top:1px solid #eee !important;
		border-bottom:1px solid #eee !important;
	}
	.header-2 .navy ul li a {
		padding: 10px 20px;
	}
	.header-2 .navy ul ul li a {
		width:100%;
		padding: 10px 20px 10px 35px;
	}
	.header-2 .navy ul ul ul li a {
		padding: 10px 20px 10px 50px;
	}
	.header-2 .navy ul ul ul ul li a {
		padding: 10px 20px 10px 65px;
	}
	.header-2 .navy > ul > li.has-sub > a:after,
	.header-2 .navy ul ul li.has-sub > a:after {
		display: none;
	}	
	.header-2 .navy #menu-button {
		position: relative;
		display: block;
		padding: 10px 20px;
		cursor: pointer;
		font-size: 13px;
		color: #fff;
		font-weight: 600;
		text-transform: uppercase;
	}
	.header-2 .navy #menu-button:after{
		content: "\f0c9";
		font-family:"FontAwesome";
		margin-left:7px;
		font-weight:400;
		float:right;
		font-size:14px;		
	}
	.header-2 .navy .submenu-button {
		position: absolute;
		right: 0;
		display: block;
		width: 50px;
		height: 50px;
		z-index: 12;
		cursor: pointer;
		border-left:1px solid #eee;
	}
	.header-2 .navy ul ul .submenu-button {
		height: 50px;
	}
	.header-2 .navy ul .submenu-button:after {
		content: "\f107";
		font-family:"FontAwesome";
		font-weight:400;
		font-size:14px;
		line-height:50px;
		text-align:center;
		display: block;
	}
}
@media (min-width:786px){
	.header-2 .navy ul{
		display:block !important;
	}
}
/* Header 2 search */
.header-2 .head-search {
	position: relative;
	top:20px;
}
.header-2 .head-search .b-dropdown-block {
	padding: 7px;
	border: 1px solid #ddd;
	width: 250px;
	position: absolute;
	background: #ffffff;
	top: 33px;
	right: 0px;
	border-radius: 2px;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
	display: none;
	z-index: 10000;
}
@media (max-width:785px){
	.header-2 .head-search{
		display:none;
	}
}
/* Main content */
/* Page heading */
/* Page heading one */
.page-heading-one {
	text-align:center;
	background:url("../../img/backgrounds/heading-back.jpg");
	background-size:cover;
	background-position:center center;
	padding:50px 10px;
	background-attachment:fixed;
	margin-bottom:30px;
	box-shadow:0px 2px 10px rgba(0,0,0,0.15);
}
.page-heading-one h2{
	color:#fff;
	font-size:40px;
	line-height:55px;
	font-weight:300;
}
.page-heading-one p{
	color:#fff;
	display:inline-block;
	font-size:15px;
	line-height:30px;
	margin-top:10px;
	padding:0px 15px;
}
@media (max-width:767px){
	.page-heading-one{
		padding:30px 10px;
	}	
	.page-heading-one h2{
		font-size:22px;
		line-height:43px;
	}	
	.page-heading-one p{
		font-size:13px;
		line-height:28px;
	}
}
/* Page heading two */
.page-heading-two{
	padding:20px 0px;
	background-color:#fcfcfc;
	border-top:1px solid #eee;
	border-bottom:1px solid #eee;
	margin-bottom:30px;
}
.page-heading-two h2{
	float:left;
	font-size:22px;
	color:#666;
	font-weight:400;
}
.page-heading-two h2 span{
	font-size:13px;
	color:#888;
}
@media (max-width:767px){
	.page-heading-two h2{
		float:none;
	}
}
.page-heading-two .breads{
	float:right;
	font-size:12px;
	color:#aaa;
	margin-top:10px;
}
.page-heading-two .breads a{
	color:#888;
}
@media (max-width:767px){
	.page-heading-two .breads{
		float:none;
		margin-top:0px;
	}
}
/* Block headings    */
/* Heading #1 */
.block-heading-one{
	margin:10px 0px 15px 0px;
}
.block-heading-one h3{
	font-size:15px;
	line-height:30px;
	border-bottom:1px solid #f3f3f3;
	padding-bottom:7px;
	margin-bottom:10px;
	font-weight:400;
}
/* Heading #2 */
.block-heading-two{
	margin:10px 0px 15px 0px;
}
.block-heading-two h3{
	font-size:18px;
	line-height:36px;
	font-weight:600;
	padding:0px 10px 6px 0px;
	margin-bottom:10px;
	border-bottom:1px solid #eee;
}
.block-heading-two h3 span{
	padding-bottom:11px;
	border-bottom:3px solid;
}
/* Heading #3 */
.block-heading-three{
	text-align:center;
	margin:10px 0px 15px 0px;
}
.block-heading-three h3{
	font-size:22px;
	line-height:37px;
	padding:0px 0px 5px 0px;
	margin-bottom:5px;
	display:inline-block;
	border-bottom:3px solid;
	font-weight:400;
}
.block-heading-three p{
	max-width:500px;
	line-height:30px;
	margin:3px auto;
	font-size:14px;
	line-height:29px;
}
/* Heading #4 */
.block-heading-four{
	text-align:center;
	margin:10px 0px 15px 0px;
}
.block-heading-four i{
	width:70px;
	height:70px;
	border:2px solid;
	line-height:70px;
	border-radius:100%;
	font-size:22px;
	margin-bottom:10px;
}
.block-heading-four h3{
	font-size:33px;
	line-height:48px;
	font-weight:300;
	padding:0px;
	margin:0 auto;
	margin-bottom:10px;
	max-width:650px;
}
@media (max-width:767px){
	.block-heading-four i{
		width:50px;
		height:50px;
		line-height:50px;
		font-size:16px;
	}	
	.block-heading-four h3{
		font-size:25px;
		line-height:40px;
	}
}
/* Heading #5 */
.block-heading-five{
	text-align:center;
	margin:10px 0px 15px 0px;
}
.block-heading-five h4{
	color:#fff;
	display:inline-block;
	padding:0px 8px;
	font-weight:600;
	text-transform:uppercase;
}
.block-heading-five h3{
	font-size:22px;
	line-height:37px;
	font-weight:normal;
	max-width:550px;
	margin:10px auto;
}
/* Heading #6 */
.block-heading-six{
	text-align:center;
	margin:10px 0px 15px 0px;
}
.block-heading-six h4{
	color:#fff;
	display:inline-block;
	padding:0px 8px;
	font-weight:600;
	margin:3px 0px;
}
/* Heading #7 */
.block-heading-seven{
	text-align:center;
	margin:10px 0px 15px 0px;
}
.block-heading-seven h3{
	margin-bottom:10px;
}
.block-heading-seven h4{
	text-transform:uppercase;
}
/* Heading #8 */
.block-heading-eight{
	margin:10px 0px 15px 0px;
}
.block-heading-eight h3{
	font-weight:400;
}
.block-heading-eight h4{
	font-weight:400;
	color:#888;
	font-size:14px;
	margin-left:28px;
}
/* Heading #9 */
.block-heading-nine{
	margin:10px 0px 15px 0px;
}
.block-heading-nine h3{
	font-size:50px;
	font-weight:600;
	text-transform:uppercase;
}
.block-heading-nine h3 small{
	font-size:22px;
	color:#999;
}
@media (max-width:767px){
	.block-heading-nine h3{
		font-size:30px;
	}
	.block-heading-nine h3 small{
		font-size:16px;
	}
}
/* Heading #10 */
.block-heading-ten{
	max-width:350px;
	margin:10px 0px 15px 0px;
}
.block-heading-ten h4{
	font-size:15px;
	line-height:20px;
	color:#999;
	margin-bottom:7px;
}
.block-heading-ten h3{
	font-weight:400;
	line-height:32px;
}
.block-heading-ten .bh-bor{
	width:60px;
	height:3px;
	margin:10px 0px;
}
.block-heading-ten p{
	color:#888;
}
/* Heading #11 */
.block-heading-eleven{
	margin:10px 0px 15px 0px;
}
.block-heading-eleven h3{
	font-size:50px;
	text-transform:uppercase;
	line-height:60px;
}
@media (max-width:767px){
	.block-heading-eleven h3{
		font-size:30px;
		line-height:40px;
	}
}
.block-heading-eleven a{
	font-size:15px;
	color:#999;
}
.block-heading-eleven a:hover{
	color:#555;
}
/* Heading #12 */
.block-heading-twelve{
	margin:10px 0px 15px 0px;
}
.block-heading-twelve h3{
	font-size:60px;
	line-height:70px;
}
.block-heading-twelve h4{
	color:#fff;
}
.block-heading-twelve h4 span{
	display:inline-block;
	padding:2px 10px;
	margin-bottom:10px;
}
.block-heading-twelve p{
	font-size:18px;
	line-height:35px;
	color:#888;
}
@media (max-width:767px){
	.block-heading-twelve h3{
		font-size:30px;
		line-height:40px;
	}
	.block-heading-twelve p{
		font-size:16px;
		line-height:30px;
	}
}
/*********************/
/* Revolution slider */
/*********************/
/* General */
.bannercontainer {
	width:100%;
	position:relative;
	padding:0;
	z-index:9;
}
.banner{
	width:100%;
	position:relative;
}
.banner ul{
	display: none;
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.tp-caption.b-big-bold{
	font-weight:600;
	font-size:50px;
	line-height:65px;
}
.tp-caption.b-big-thin-transparent{
	font-weight:300;
	font-size:35px;
	line-height:50px;
	padding:0px 10px;
	background:rgba(0,0,0,0.5);
}
.tp-caption.b-medium-bold-grey{
	color:#999;
	font-weight:600;
	font-size:30px;
	line-height:45px;
}
.tp-caption.b-medium-thin-grey{
	color:#888;
	font-weight:300;
	font-size:30px;
	line-height:45px;
}
.tp-caption.b-small-bold-grey{
	font-size:22px;
	line-height:37px;
	color:#999;
}
.tp-caption.b-small-para{
	font-size:13px;
	line-height:28px;
	font-weight:400;
}
.tp-caption.b-medium-back{
	font-size:22px;
	line-height:37px;
	color:#fff;
	padding:5px 15px;
}
.tp-caption.b-button a{
	display:inline-block;
	padding:5px 18px;
	color:#fff;
}
.tp-caption.head-para{
	max-width:350px;
	color:#fff;
	background:rgba(0,0,0,0.5);
	padding:15px;
}
.tp-caption.head-para h4{
	color:#fff;
	font-weight:400;
	text-transform:none;
}		
@media (max-width:600px){
	.tp-caption.head-para h4{
		font-size:15px;
	}	
	.tp-caption.head-para p{
		display:none;
	}	
}	
/* Revolution slider one */
.r-slider .banner{
	border-top:1px solid #ddd;
	border-bottom:1px solid #ddd;
}
/* Revolution slider alt */
/**************/
/* Smart form */
/**************/
.smart-forms,
.smart-forms label, 
.smart-forms input,
.smart-forms button,
.smart-forms select,  
.smart-forms textarea  {
	font-family:"Open Sans", sans-serif !important;
}
.smart-forms .radio, 
.smart-forms .checkbox {
	display: inline-block;
	min-height: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-left: 0px;
}
.smart-forms .checkbox:before{
	box-sizing:content-box;
}
.smart-forms .ui-slider .ui-slider-range, 
.smart-forms .progress > button[type="submit"], 
.smart-forms .progress > button[type="submit"]:hover, 
.smart-forms .progress-bar > .bar{
	background-image:none;
}
/* Hover & Focus  */
.smart-forms .gui-input:hover,
.smart-forms .gui-textarea:hover,
.smart-forms .select > select:hover,
.smart-forms .select-multiple select:hover, 
.smart-forms .gui-input:hover ~ .input-hint,
.smart-forms .file .gui-file:hover + .gui-input,
.smart-forms .gui-textarea:hover ~ .input-hint{ border-color: #BDC3C7; }
.smart-forms .gui-input:focus,
.smart-forms .gui-textarea:focus,
.smart-forms .select > select:focus,
.smart-forms .select-multiple select:focus{ border-color: #BDC3C7; color: #000; }
.smart-forms .gui-textarea:focus{  height: 120px; }
.smart-forms .select > select:focus { z-index:10; z-index:20\9; }
.smart-forms .gui-input:focus ~ .field-icon i,
.smart-forms .gui-textarea:focus ~ .field-icon i{ color:#BDC3C7; }
.smart-forms .select-multiple select:focus, 
.smart-forms .gui-input:focus ~ .input-hint,
.smart-forms .gui-textarea:focus ~ .input-hint, 
.smart-forms .file .gui-file:focus + .gui-input{ border-color: #BDC3C7; }
.smart-forms .select > select:focus + .arrow{ color:#BDC3C7; }
.ui-datepicker { 
	border:1px solid #ddd;
	box-shadow:none;
	font-family:"Open Sans", sans-serif;
}
.ui-datepicker:before{ 
	border-color:#ddd; 
}
.ui-timepicker-div .ui-widget-header,
.ui-datepicker .ui-datepicker-header { background:#fafafa; }
.ui-timepicker-div .ui-widget-header *,
.ui-datepicker .ui-datepicker-header  *{ color:#666 !important }
/* Radio & Checkbox */
.smart-forms input:hover + .checkbox,  
.smart-forms input:hover + .radio{ border-color: #BDC3C7; }
.smart-forms input:checked + .checkbox, 
.smart-forms input:focus + .checkbox, 
.smart-forms input:checked + .radio, 
.smart-forms input:focus + .radio{ border-color: #BDC3C7; }
.smart-forms input:checked + .radio:before, 
.smart-forms input:focus + .radio:before{  background: #BDC3C7; }
.smart-forms input:checked + .checkbox:before, 
.smart-forms input:focus + .checkbox:before{ border-color: #BDC3C7; }
/* Rating */
.smart-forms .rating-star, 
.smart-forms .rating:hover .rating-star {	color: #BDC3C7;	 }
/**********************/
/* Bootstrap carousel */
/**********************/
.carousel{
	margin:10px auto;
}
.carousel .carousel-control.right {
	left: auto;
	right: 0;
	background-image: none;
	background-image: none;
	background-image: none;
	background-repeat: no-repeat; 
	filter: none;
}
.carousel .carousel-control.left {
	background-image: none;
	background-image: none;
	background-image: none;
	background-repeat: no-repeat; 
	filter: none;
}
.carousel .carousel-control {
	position: absolute;
	z-index:7;
	top: 45%;
	width: 15%;
	opacity: .7;
	filter: alpha(opacity=70);
	font-size: 15px;
	color: #fff;
	text-align: center;
	text-shadow: none;
}
.carousel .carousel-control:hover, 
.carousel .carousel-control:focus {
	outline: 0;
	color: #fff;
	text-decoration: none;
	opacity: 1;
	filter: alpha(opacity=100);
}
.carousel .carousel-control span{
	display:inline-block;
	width:35px;
	height:35px;
	background:rgba(0,0,0,0.4);
	line-height:35px;
	border-radius:3px;
}
.carousel .carousel-indicators li {
	display: inline-block;
	width: 11px;
	height: 11px;
	margin: 1px;
	text-indent: -999px;
	border: 2px solid #fff;
	border-radius: 10px;
	cursor: pointer;
	background-color: #000 \9;
	background-color: rgba(0,0,0,0);
}
.carousel .carousel-indicators .active {
	margin: 0;
	width: 13px;
	height: 13px;
}
@media (max-width:480px){
	.carousel .carousel-indicators{
		display:none;
	}
	.carousel .carousel-control{
		top:40%;
	}
	.carousel .carousel-control span{
		width:auto;
		height:auto;
		background:transparent;
		line-height:0px;
		border-radius:0px;
	}
}
/* Carousel caption */
.carousel .carousel-caption{
	text-shadow:none;
	position:absolute;
	background:rgba(0,0,0,0.7);
	max-width:350px;
	overflow:hidden;
	padding:15px 20px;
	text-align:left;
	z-index:5;
}
@media (max-width:550px){
	.carousel .carousel-caption{
		display:none;
	}
}
.carousel .item .carousel-caption h4,
.carousel .item .carousel-caption h4 a{
	font-size:16px;
	line-height:29px;
	color:#fff;
}
.carousel .item .carousel-caption p{
	color:#ccc;
	font-size:13px;
	line-height:23px;
}
.carousel .item .carousel-caption.c-middle-left{
	top:30%;
	left:7%;
	bottom:auto;
	right:auto;
}
.carousel .item .carousel-caption.c-middle-right{
	top:30%;
	right:7%;
	bottom:auto;
	left:auto;
}
.carousel .item .carousel-caption.c-bottom-left{
	bottom:7%;
	left:5%;
	top:auto;
	right:auto;
}
.carousel .item .carousel-caption.c-bottom-right{
	bottom:7%;
	right:5%;
	top:auto;
	left:auto;
}
.carousel .item .carousel-caption.c-bottom-full{
	bottom:0%;
	left:0%;
	right:auto;
	top:auto;
	max-width:100%;
	width:100%;
	padding:12px 15px;
}
.carousel .item .carousel-caption.c-left-big{
	bottom:0%;
	left:0%;
	top:auto;
	right:auto;
	height:100%;
	padding:30px 25px;
}
.carousel .item .carousel-caption.c-right-big{
	bottom:0%;
	right:0%;
	top:auto;
	left:auto;
	height:100%;
	padding:30px 25px;
}
/* Carousel style one */
.carousel.carousel-one .item{
	padding:120px 20px;
	text-align:center;
}
.carousel.carousel-one h5{
	font-size:22px;
	line-height:35px;
	font-weight:400;
	color:rgba(255,255,255,0.8);
	text-transform:uppercase;
}
.carousel.carousel-one h3{
	font-size:70px;
	line-height:85px;
	font-weight:600;
	color:#fff;
	text-transform:uppercase;
}
.carousel.carousel-one .btn{
	margin-top:10px;
	font-size:14px;
}
@media (max-width:767px){
	.carousel.carousel-one .item{
		padding:60px 20px;
	}
	.carousel.carousel-one h5{
		font-size:15px;
		font-weight:600;
	}
	.carousel.carousel-one h3{
		font-size:30px;
		line-height:45px;
	}
}
/* Carousel three */
.carousel.carousel-two .carousel-two-content{
	position:absolute;
	top:50%;
	width:100%;
	text-align:center;
}
.carousel.carousel-two  h3{
	color:#fff;
	font-weight:300;
	font-size:60px;
	line-height:60px;
	margin-top:-50px;
	background:rgba(0,0,0,0.4);
	padding:20px 0px;
}
.carousel.carousel-two   h3 a,
.carousel.carousel-two   h3 a:hover{
	color:#fff;
}
@media (max-width:767px){
	.carousel.carousel-two   h3{
		font-size:30px;
		line-height:30px;
		margin-top:-15px;
	}
}
@media (max-width:480px){
	.carousel.carousel-two   h3{
		font-size:20px;
		line-height:20px;
		margin-top:-15px;
		padding:0px;
		background:transparent;
	}
}
/*************/
/* Fun facts */
/*************/
.facts{
	margin:40px auto;
}
.facts .fact-value h4{
	font-size:28px;
	line-height:43px;
	text-align:center;
	font-weight:400;
}
.facts .fact-value span{
	font-size:22px;
	margin:0px 2px;
}
.facts .fact-content p span{
	font-weight:700;
}
@media (max-width: 991px){
	.facts .fact-container{
		max-width:350px;
		margin:0 auto;
	}
}
/* Support */
.support .support-filter  input{
	width:200px;
	padding:6px 6px 6px 10px;
	border-radius:3px;
	margin-top:10px;
	border:1px solid #ddd;
}
.support .support-filter li a{
	display: block;
	margin:12px 0px;
	color:#777;
}
.support .support-filter li a:before{
	font-family:FontAwesome;
	content:"\f105";
	color:#aaa;
	margin-right:8px;
}
.support .support-filter li p{
	display:none;
	background:#fcfcfc;
	margin:5px 0px 10px 14px;
	padding:12px 15px;
	border:1px solid #ddd;
}
.support .s-contact i{
	color:#fff;
}
.support .s-contact .s-list{
	margin:8px 0px;
}
/***********/
/* Service */
/***********/
/* Service three */
.service-three .s3-para{
	font-size:16px;
	line-height:35px;
	color:#999;
}
/* Service four */
.service-four .service-logo img{
	width:100%;
	max-width:200px;
	display:inline-block;
	margin:20px auto;
	border-radius:4px;
	padding:2px;
	box-shadow:0px 0px 3px rgba(0,0,0,0.2);
}
.service-four .s4-item{
	margin:10px 0px;
}
.service-four .s4-item h4{
	font-weight:400;
}
@media (max-width:767px){
	.service-four .s4-item{
		text-align:center;
	}	
}	
/****************/
/* About us one */
/****************/
.about-us-one .about-logo{
	margin:10px 0px;
}
.about-us-one .about-logo h2{
	font-size:30px;
	line-height:45px;
	font-weight:400;
}
.about-us-one .about-logo h2 small{
	font-size:16px;
}
.about-us-one .about-logo p{
	font-size:17px;
	line-height:32px;
	color:#999;
}
/******************/
/* About us three */
/******************/
.about-us-three .about-hero{
	font-size:18px;
	line-height:36px;
	color:#888;
	text-align:center;	
	margin:10px 0px;
}
/* Our Process */
.our-process h4{
	margin-top:10px;
}
.our-process .our-process-item{
	margin:15px auto;
	width:170px;
	text-align:center;
	color:#fff;
}
.our-process a{
	width:170px;
	height:170px;
	border-radius:100px;
	display:inline-block;
	border:3px solid #fff;
	box-shadow:0px 0px 2px #ddd;
	position:relative;
}
.our-process a  i{
	font-size:50px;
	line-height:170px;
}
.our-process img{
	position:absolute;
	right:-50px;
	top:70px;
}
/* Our process one */
.our-process-one .op-one-item{
	margin:15px 0px;
}
.our-process-one h5{
	color:#fff;
	display:inline-block;
	margin-bottom:5px;
	padding:0px 8px;
}
.our-process-one p{
	font-size:14px;
	line-height:29px;
	color:#888;
}	
/* Careers */
.career-content{
	padding:10px;
}
.career-content .table td,
.career-content .table th{
	font-size:13px;
	line-height:28px;
}
.career-content .table td,
.career-content .table th{
	border:0px;
	padding:5px 10px;
}
/* FAQ */
/* FAQ Alt */
.faq-alt .faq-alt-item{
	margin:20px 0px;
}
.faq-alt h4{
	font-size:22px;
	line-height:37px;
	font-weight:400;
}
.faq-alt p{
	font-size:15px;
	line-height:30px;
	color:#888;
}
/* FAQ three */
.faq-three h3{
	font-size:22px;
	line-height:37px;
	font-weight:400;
}
.faq-three p{
	font-size:14px;
	line-height:29px;
	color:#888;
}
/* Sitemap */
.sitemap .sitemap-top{
	max-width:400px;
}
.sitemap .sitemap-top form{
	max-width:250px;
	margin-top:10px;
}
.sitemap hr{
	margin:8px 0px;
}
.sitemap ul{
	padding-left:20px;
}
.sitemap ul li{
	margin:7px 0px;
}
.sitemap ul li a{
	color:#777;
}
/* Our product */
.our-product{
	margin:20px 0px;
}
.our-product .product-top{
	margin-bottom:15px;
}
.our-product .product-top img{
	max-width:600px;
	margin:10px auto;
}
.our-product .product-top h2{
	font-size:35px;
	line-height:60px;
}
.our-product .product-top p{
	font-size:18px;
	line-height:33px;
	color:#888;
	margin-bottom:10px;
}
/* CTA Blocks */
/* CTA  One */
.cta-one .cta-one-content{
	border:1px solid #eee;
	padding:20px 25px;
	border-radius:5px;
}
.cta-one ul{
	margin:0px;
	padding:0px;
	padding-left:15px;
}
.cta-one .btn{
	text-align:center;
	margin-top:15px;
}
/* CTA Two */
.cta-two .cta-two-content{
	border:1px solid #eee;
	padding:20px 25px;
	border-radius:5px;
}
.cta-two .btn{
	margin-top:15px;
}
@media (max-width:767px){
	.cta-two{
		text-align:center;
	}
}
/* CTA Three */
.cta-three .cta-three-content{
	max-width:1000px;
	margin:5px auto;
}
.cta-three h4{
	font-size:22px;
	line-height:37px;
	font-weight:400;
}
@media (max-width:991px){
	.cta-three .cta-three-content{
		text-align:center;
	}
	.cta-three .btn{
		margin-top:10px;
	}
}
/* CTA Four */
.cta-four .cta-four-content{
	text-align:center;
}
.cta-four p{
	margin:5px auto 10px auto;
	max-width:700px;
}
/* CTA Five */	
.cta-five .cta-five-content{
	text-align:center;
}
.cta-five  span i{
	width:50px;
	height:50px;
	line-height:50px;
	text-align:center;
	border-radius:80px;
	font-size:25px;
	color:#fff;
	margin-bottom:10px;
}
.cta-five p{
	margin:5px auto 10px auto;
	max-width:700px;
}
.cta-five  input{
	border:1px solid #ccc;
}
@media (max-width:767px){
	.cta-five form{
		max-width:350px;
		margin:10px auto;
	}
}
/* CTA Six  */
.cta-six{
	background:#fff url("../../img/backgrounds/2.jpg") repeat;
	background-size:cover;
	background-attachment:fixed;
	padding:70px 0px;
}
.cta-six .cta-six-content{
	max-width:1000px;
	border:2px solid rgba(255,255,255,0.2);
	border-radius:8px;
	padding:50px 20px 40px 20px;
	margin:0px auto;
	text-align:center;
	background:rgba(0,0,0,0.1);
}
.cta-six h4{
	font-size:22px;
	font-weight:400;
	display:inline-block;
	color:#fff;
}
.cta-six .btn{
	background:rgba(0,0,0,0.1);
	border:1px solid rgba(255,255,255,0.4);
	color:#fff;
	-webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}
.cta-six a.btn:hover{
	background:rgba(255,255,255,0.3);
}
@media (max-width:991px){
	.cta-six .cta-three-content{
		text-align:center;
	}
	.cta-six .btn{
		margin-top:10px;
	}
}
/* CTA Seven  */
.cta-seven .cta-seven-content{
	text-align:center;
}
.cta-seven  li a i{
	width:80px;
	height:80px;
	line-height:80px;
	border-radius:100px;
	text-align:center;
	margin:0px 8px;
	font-size:30px;
}	
.cta-seven h4{
	font-size:38px;
	margin-top:15px;
	font-weight:300;
	line-height:53px;
	margin-bottom:15px;
}
@media (max-width:767px){
	.cta-seven li a i{
		width:50px;
		height:50px;
		line-height:50px;
		font-size:18px;
		margin:0px 2px;
	}
	.cta-seven h4{
		font-size:28px;
		line-height:43px;
	}
}
/* CTA Eight  */
.cta-eight .cta-eight-content{
	text-align:center;
}
.cta-eight h4{
	color:#fff;
	display:inline-block;
	padding:5px 14px;
}
.cta-eight h3{
	font-size:42px;
	line-height:57px;
	font-weight:600;
	margin:15px 0px;
}
@media (max-width:767px){
	.cta-eight h3{
		font-size:30px;
		line-height:45px;
	}
}
.cta-eight p{
	font-size:16px;
	line-height:31px;
	max-width:700px;
	margin:0 auto;
	margin-bottom:20px;
}
.cta-eight ul li{
	font-size:30px;
}
@media (max-width:767px){
	.cta-eight p{
		font-size:14px;
		line-height:29px;
	}
	.cta-eight ul li{
		font-size:20px;
	}
}
.cta-eight ul li a{
	color:#777;
}
.cta-eight ul li small{
	width:40px;
	height:40px;
	line-height:40px;
	border-radius:100px;
	text-align:center;
	display:inline-block;
	color:#fff;
	font-weight:bold;
	font-size:16px;
	position:relative;
	top:-5px;
}
@media (max-width:767px){
	.cta-eight ul li small{
		width:30px;
		height:30px;
		line-height:30px;
		font-size:13px;
	}
}
/* Image styles */
.image-block{
	max-width: 350px;
	margin: 20px auto;
}
.image-block h4 a{
	color:#fff;
}
/* Image style #1 */
.image-style-one {
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.image-style-one img {
	border-radius: 3px;
	-webkit-transition: all 2s ease;
	   -moz-transition: all 2s ease;
		-ms-transition: all 2s ease;
		 -o-transition: all 2s ease;
			transition: all 2s ease;
}
.image-style-one .image-hover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	border-radius: 3px;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.image-style-one:hover .image-hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.image-style-one:hover img{
	-webkit-transform: scale(1.2) rotate(8deg);
	   -moz-transform: scale(1.2) rotate(8deg);
		-ms-transform: scale(1.2) rotate(8deg);
		 -o-transform: scale(1.2) rotate(8deg);
			transform: scale(1.2) rotate(8deg);
}
.image-style-one  .image-caption {
	margin-top: 12%;
	text-align: center;
}
.image-style-one  .image-caption h4 {
	color: #fff;
	margin-bottom:0px;
}
.image-style-one .image-caption p{
	max-width:75%;
	margin:0px auto;
	color:rgba(255,255,255,0.8);
	line-height:23px;
	margin-bottom:5px;
}
.image-style-one .image-caption a{
	display:inline-block;
	width:30px;
	height:30px;
	text-align:center;
	color:#fff;
	border-radius:2px;
	line-height:30px;
}
/* Image style #2 */
.image-style-two {
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.image-style-two img {
	border-radius: 3px;
	-webkit-transition: all 2s ease;
	   -moz-transition: all 2s ease;
		-ms-transition: all 2s ease;
		 -o-transition: all 2s ease;
			transition: all 2s ease;
}
.image-style-two .image-hover {
	position: absolute;
	display: block;
	top: 100%;
	left: 0;
	z-index: 25;
	width: 100%;
	height: 0%;
	background: rgba(0,0,0,0.7);
	border-radius: 3px;
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.image-style-two:hover .image-hover {
	top: 0;
	height:100%
}
.image-style-two  .image-caption {
	position: absolute;
	top: 10%;
	text-align: center;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.6s ease-in;
	-moz-transition: all 0.6s ease-in;
	-ms-transition: all 0.6s ease-in;
	-o-transition: all 0.6s ease-in;
	transition: all 0.6s ease-in;
	z-index:35;
}
.image-style-two:hover  .image-caption {
	opacity:1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.image-style-two  .image-caption h4 {
	color: #fff;
	margin-bottom:0px;
}
.image-style-two .image-caption p{
	max-width:75%;
	margin:0px auto;
	color:rgba(255,255,255,0.8);
	line-height:23px;
	margin-bottom:5px;
}
.image-style-two .image-caption a{
	display:inline-block;
	width:30px;
	height:30px;
	text-align:center;
	color:#fff;
	border-radius:2px;
	line-height:30px;
}
/* Image style #2 */
.image-style-three {
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.image-style-three img {
	border-radius: 3px;
}
.image-style-three .image-hover {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	border-radius: 3px;
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.image-style-three:hover .image-hover {
	top: 0;
}
.image-style-three  .image-caption {
	margin-top: 20%;
	text-align: center;
}
.image-style-three   .image-caption h4 a,
.image-style-three   .image-caption h4 {
	color: #fff;
}
.image-style-three .image-caption p{
	max-width:75%;
	margin:0px auto;
	color:#fff;
	line-height:23px;
}
/* Image style four */
.image-style-four {
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.image-style-four img {
	border-radius: 3px;
}
.image-style-four  .image-caption {
	position:absolute;
	bottom:0px;
	width:100%;
	padding:5px 15px;
	border-bottom-left-radius:3px;
	border-bottom-right-radius:3px;
}
.image-style-four  .image-caption h4 {
	color: #fff;
	font-size:14px;
	font-weight:normal;
}
.image-style-four  .image-caption h4 span a i{
	color:#fff;
	margin-left:2px;
}
/* Image style five */
.image-style-five{
	position:relative;
	z-index:10;
	overflow:hidden;
}
.image-style-five img {
	border-radius: 3px;
}
.image-style-five .image-hover{
	width:100%;
	height:100%;
	border-radius:3px;
	position:absolute;
	top:0px;
	background:rgba(0,0,0,0.3);
}	
.image-style-five  .image-caption {
	width:100%;
	position:absolute;
	top:25%;
	text-align:center;
}
.image-style-five  .image-caption h4 {
	color: #fff;
	font-size:20px;
	display:inline-block;
	border-bottom:3px solid;
	padding-bottom:3px;
}
.image-style-five .image-caption p{
	margin:0px auto;
	margin-top:3px;
	max-width:70%;
	line-height:23px;
	color:#fff;
}
/* Image style six */
.image-style-six {
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.image-style-six img {
	border-radius: 3px;
}
.image-style-six  .image-caption {
	position:absolute;
	top:50%;
	margin-top:-20px;
	left:-100%;
	background:rgba(0,0,0,0.6);
	height:40px;
	padding:4px 15px;
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.image-style-six:hover .image-caption{
	left:0px;
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.image-style-six  .image-caption h4 {
	color: #fff;
	font-size:15px;
	line-height:28px;
}
/* Image style #7 */
.image-style-seven {
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.image-style-seven img {
	border-radius: 3px;
	-webkit-transition: all 2s ease;
	   -moz-transition: all 2s ease;
		-ms-transition: all 2s ease;
		 -o-transition: all 2s ease;
			transition: all 2s ease;
}
.image-style-seven .image-hover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	border-radius: 3px;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.image-style-seven:hover .image-hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.image-style-seven  .image-caption {
	margin-top: 30%;
	text-align: center;
}
.image-style-seven .image-caption a i{
	display:inline-block;
	width:35px;
	height:35px;
	border-radius:100px;
	margin:0px 2px;
	line-height:35px;
	color:#fff;
}
/* Image style eight */
.image-style-eight{
	position:relative;
	z-index:10;
}
.image-style-eight img {
	border-radius: 3px;
}
.image-style-eight .image-hover{
	width:100%;
	height:100%;
	border-radius:3px;
	position:absolute;
	top:0px;
	background:rgba(0,0,0,0.3);
}	
.image-style-eight  .image-caption {
	width:100%;
	position:absolute;
	bottom:-17px;
	text-align:center;
}
.image-style-eight  .image-caption a i{
	display:inline-block;
	width:40px;
	height:40px;
	line-height:40px;
	margin:0px 2px;
	border-radius:100px;
	color:#fff;
}
/* Icon Box #1 */
.icon-box-1{
	margin:20px 0px;
}
.icon-box-1 .icon-box-1-item{
	width:100%;
	max-width:280px;
	margin:10px auto;
}
.icon-box-1 a i{
	font-size:50px;
	position:relative;
	top:10px;
	margin-bottom:20px;
	-webkit-transition:all .4s linear;
	-moz-transition:all .4s linear;
	-o-transition:all .4s linear;
	-ms-transition:all .4s linear;
	transition:all .4s linear;
}
.icon-box-1 a i:hover{
	top:0px;
}
.icon-box-1 h4 a{
	color:#888 !important;
}
.icon-box-1 p{
	color:#888;
}
/* Icon Box #2 */
.icon-box-2{
	margin:20px 0px;
}
.icon-box-2 .icon-box-2-item{
	width:100%;
	max-width:280px;
	margin:10px auto;
}
.icon-box-2 .header{
	overflow: hidden;
	height:140px;
}
.icon-box-2 .header a{
	display:inline-block;
	padding:40px 0px;
	font-size:50px;
}
.icon-box-2 .header a.icon-top{
	color:#fff;
	position:relative;
	display:block;
	top:10px;
	-webkit-transition:all .13s linear;
	-moz-transition:all .13s linear;
	-o-transition:all .13s linear;
	-ms-transition:all .13s linear;
	transition:all .13s linear;
}
.icon-box-2 .header:hover a.icon-top{
	top:100px;
}
.icon-box-2 .header a.icon-bottom{
	color:#fff;
	display:block;
	position:relative;
	top:100px;
	-webkit-transition:all .1s linear;
	-moz-transition:all .1s linear;
	-o-transition:all .1s linear;
	-ms-transition:all .1s linear;
	transition:all .1s linear;
}
.icon-box-2 .header:hover a.icon-bottom{
	top:-120px;
}
.icon-box-2 .icon-box-2-title{
	background:#555;
}
.icon-box-2 .icon-box-2-title h4{
	padding:15px 0px;
}
.icon-box-2 .icon-box-2-title h4 a{
	color:#fff;
}
/* Icon Box #3 Starts */
.icon-box-3{
	margin:20px 0px;
}
.icon-box-3 .icon-box-3-item{
	color:#fff;
	padding:25px;
	max-width:280px;
	margin:10px auto;
}
.icon-box-3 i{
	font-size:35px;
	margin-bottom:8px;
}
.icon-box-3 h4 a{
	color:#fff;
}
.icon-box-3 a,
.icon-box-3 a:hover{
	color:rgba(0, 0, 0, 0.3);
	font-weight:600;
}
.icon-box-3 h4 a:hover{
	color:#fff;
}
.icon-box-3 a i{
	font-size:14px;
	margin-left:3px;
}
/* Icon Box #4 */
.icon-box-4{
	margin:20px 0px;
}
.icon-box-4 .icon-box-4-item{
	width:100%;
	max-width:280px;
	margin:10px auto;
}
.icon-box-4 a.icon-box-4-icon{
	width:32px;
	height:32px;
	line-height:32px;
	border-radius:100px;
	display:inline-block;
	color:#fff;
	font-size:16px;
	text-align:center;
	margin-right:5px;
}
.icon-box-4 h4{
	display:inline;
}
.icon-box-4 h4 a{
	color:#888;
}
.icon-box-4 p{
	color:#888;
}
/* Icon box #5  */
.icon-box-5{
	margin:20px 0px;
}	
.icon-box-5 .icon-box-5-item{
	padding:20px;
	max-width:280px;
	color:#fff;
	margin:10px auto;
}
.icon-box-5 h4 a{
	color:#fff;
}
.icon-box-5 h4 i{
	margin-right:10px;
}
.icon-box-5 a,
.icon-box-5 a:hover{
	color:#fff;
	font-weight:600;
}
.icon-box-5 a i{
	margin-left:3px;
}
/* Icon Box #6  */
.icon-box-6{
	margin:20px 0px;
}
.icon-box-6 .icon-box-6-item{
	max-width:250px;
	margin:10px auto;
}
.icon-box-6 i{
	width:50px;
	height:50px;
	line-height:50px;
	border-radius:100px;
	text-align:center;
	color:#fff;
	font-size:22px;
	margin-bottom:10px;
}
.icon-box-6 h4 a{
	color:#888;
}
.icon-box-6 p{
	color:#888;
}
/* Icon Box #7  */
.icon-box-7{
	margin:20px 0px;
}
.icon-box-7 .icon-box-7-item{
	width:100%;
	max-width:300px;
	margin:10px auto;
}
.icon-box-7 .icon-box-7-item-left{
	float:left;
	width:85px;
}
.icon-box-7 .icon-box-7-item-left a.icon-box-7-icon{
	width:80px;
	height:80px;
	line-height:80px;
	display:inline-block;
	color:#fff;
	font-size:30px;
	border-radius:3px;
	text-align:center;
	margin-top:5px;
}
.icon-box-7 .icon-box-7-item-right{
	position:relative;
	margin-left:95px;
}
.icon-box-7 .icon-box-7-item-right h4{
	margin-top:0px;
}
.icon-box-7 .icon-box-7-item-right h4 a{
	color:#888;
}
.icon-box-7 .icon-box-7-item-right p{
	color:#888;
}
/* Icon Box #8 */
.icon-box-8{
	margin:20px 0px;
}	
.icon-box-8 .icon-box-8-item{
	max-width:260px;
	margin:10 auto;
	padding:30px;
	color:#fff;
	box-shadow:0px 0px 10px #fff;
	border-top:3px solid rgba(0, 0, 0, 0.2);
	border-radius:3px;
}
.icon-box-8 .icon-box-8-icon{
	width:80px;
	height:80px;
	line-height:80px;
	border-radius:100px;
	text-align:center;
	box-shadow:inset 0px 0px 2px rgba(0, 0, 0, 0.3);
	background:rgba(0, 0, 0, 0.2);
	overflow:hidden;
	margin:0 auto;
	margin-bottom:10px;
}
.icon-box-8 .icon-box-8-icon a{
	display:inline-block;
	font-size:30px;
}
.icon-box-8 .icon-box-8-icon a.icon-box-8-icon-top{
	color:#fff;
	position:relative;
	display:block;
	top:0px;
	-webkit-transition:all .13s linear;
	-moz-transition:all .13s linear;
	-o-transition:all .13s linear;
	-ms-transition:all .13s linear;
	transition:all .13s linear;
}
.icon-box-8 .icon-box-8-icon:hover a.icon-box-8-icon-top{
	top:100px;
}
.icon-box-8 .icon-box-8-icon a.icon-box-8-icon-bottom{
	color:#fff;
	display:block;
	position:relative;
	top:100px;
	-webkit-transition:all .1s linear;
	-moz-transition:all .1s linear;
	-o-transition:all .1s linear;
	-ms-transition:all .1s linear;
	transition:all .1s linear;
}
.icon-box-8 .icon-box-8-icon:hover a.icon-box-8-icon-bottom{
	top:-80px;
}
.icon-box-8 .icon-box-8-item h4 a{
	color:#fff;
}
/* Icon Box #9 Starts */
.icon-box-9{
	margin:20px 0px;
}
.icon-box-9 .icon-box-9-item{
	max-width:280px;
	padding:15px 10px 15px 20px;
	position:relative;
	margin:10px auto;
}
.icon-box-9 .icon-box-9-item a.icon-box-9-icon i{
	font-size:20px;
	margin-right:5px;
	color:#777;
}
.icon-box-9 .icon-box-9-item:hover h4 a,
.icon-box-9 .icon-box-9-item:hover a.icon-box-9-icon  i,
.icon-box-9 .icon-box-9-item:hover p{
	color:#fff;
}
.icon-box-9 .icon-box-9-item h4{
	display:inline;
}
.icon-box-9 .icon-box-9-item h4 a{
	color:#777;
}
.icon-box-9 .icon-box-9-item p{
	color:#888;
}
/* Icon Box #10 */
.icon-box-10{
	margin:20px 0px;
}
.icon-box-10 .icon-box-10-content{
	max-width:280px;
	margin:10px auto;
}
.icon-box-10 .icon-box-left{
	float:left;
	width:35px;
	text-align:center;
	padding-top:5px;
}
.icon-box-10 .icon-box-left a{
	font-size:28px;
}
.icon-box-10 .icon-box-right{
	margin-left:45px;
}
.icon-box-10 .icon-box-right h4 a{
	color:#888;
}
.icon-box-10 .icon-box-right p{
	color:#777;
}
/* Image Box #1  */
.img-box-1{
	margin:20px 0px;
}
.img-box-1 .img-box-1-item{
	text-align:center;
	margin:10px auto;
	max-width:250px;
}
.img-box-1 .img-box-1-img{
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.img-box-1 img {
	-webkit-transition: all 2s ease;
	   -moz-transition: all 2s ease;
		-ms-transition: all 2s ease;
		 -o-transition: all 2s ease;
			transition: all 2s ease;
}
.img-box-1 .image-hover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.img-box-1 .img-box-1-item:hover .image-hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.img-box-1  .image-caption {
	margin-top: 30%;
	text-align: center;
}
.img-box-1 .image-caption a i{
	display:inline-block;
	width:35px;
	height:35px;
	border-radius:2px;
	margin:0px 2px;
	line-height:35px;
	color:#fff;
}
.img-box-1 .img-box-1-cap{
	background:#fff;
	padding:12px 20px 15px;
	text-align:center;
	border:1px solid #eee;
	border-top:0px;
}
.img-box-1 h4{
	font-size:16px;
	font-weight:600;
	text-transform:uppercase;
}
.img-box-1 h4 a{
	color:#777;
}
.img-box-1 .btn{
	margin-top:5px;
}
/* Image Box #2  */
.img-box-2{
	margin:20px 0px;
}
.img-box-2 .img-box-2-item{
	position:relative;
	max-width:250px;
	margin:10px auto;
	z-index: 10;
	overflow: hidden;
}
.img-box-2 .image-hover {
	position: absolute;
	z-index: 5;
	top: 0;
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";			
}
.img-box-2 .img-box-2-item:hover .image-hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";	
}
.img-box-2  .image-caption {
	margin-top: 20%;
	text-align: center;
}
.img-box-2  .image-caption h4 a{
	color: #fff;
}
.img-box-2 .image-caption p{
	max-width:75%;
	margin:0px auto;
	line-height:23px;
	color:#fff;
}
/* Image Box #3  */
.img-box-3{
	margin:20px 0px;
}
.img-box-3 .img-box-3-item{
	max-width:240px;
	margin:10px auto;
}
.img-box-3 .img-box-3-icon{
	width:110px;
	height:110px;
	line-height:110px;
	border-radius:100px;
	text-align:center;
	margin:10px auto;
}
.img-box-3 .img-box-3-icon  a img{
	max-width:60px;
	display:inline-block;
}
.img-box-3 h4 a{
	color:#777;
}
.img-box-3 .bor{
	width:60px;
	height:3px;
	margin:0 auto;
	margin-top:10px;
	margin-bottom:6px;
}
/* Image Box #4  */
.img-box-4{
	margin:20px 0px;
}
.img-box-4 .img-box-4-item{
	max-width:260px;
	margin:10px auto;
}
.img-box-4 .img-box-4-content{
	border:1px solid #eee;
	border-top:0px;
	padding:22px 20px 15px;
	position:relative;
}
.img-box-4 a i{
	width:40px;
	height:40px;
	line-height:40px;
	text-align:center;
	color:#fff;
	border-radius:50px;
	display:inline-block;
	position:absolute;
	top:-25px;
	left:50%;
	margin-left:-20px;
	font-size:15px;
}
.img-box-4 h4 a{
	color:#777;
}
.img-box-4 .bor{
	width:55px;
	height:3px;
	margin:0 auto;
	margin-top:12px;
	margin-bottom:8px;
}
/* Image Box #5  */
.img-box-5{
	margin:20px 0px;
}
.img-box-5 .img-box-5-item{
	margin:10px auto;
}
.img-box-5 .img-box-5-content h4 a{
	color:#777;
}
.img-box-5 .img-box-5-content .bor{
	width:60px;
	height:3px; 
	margin-top:5px;
	margin-bottom:5px;
}
@media (max-width:991px){
	.img-box-5 .img-box-5-item{
		max-width:300px;
	}
	.img-box-5 .img-box-5-img{
		margin-bottom:20px;
	}
}
/* Image Box #6  */
.img-box-6{
	margin:20px 0px;
}
.img-box-6 .img-box-6-item{
	position:relative;
	border:2px solid #fff;
	border-radius:3px;
	margin:10px auto;
	max-width:450px;
	box-shadow:0px 0px 3px rgba(0,0,0,0.2);
}
.img-box-6 .img-box-6-content{
	position:absolute;
	bottom:0px;
	background:rgba(0, 0, 0, 0.4);
	color:#fff;
	padding:10px 15px; 
	text-shadow:0px 0px 2px rgba(0,0,0,0.3);
}
.img-box-6 h4 a{
	color:#fff;
}
.img-box-6 p{
	color:#fff;
	line-height:20px;
}
/* Image Box #7 Starts */
.img-box-7{
	margin:20px 0px;
}
.img-box-7 .img-box-7-item{
	max-width:250px;
	margin:10px auto;
	text-align:center;
}
.img-box-7 a img{
	display:inline-block;
	max-height:80px;
	margin-bottom:10px;
}
.img-box-7 h4 a{
	color:#777;
}
.img-box-7 .bor{
	width:50px;
	height:3px;
	margin:0 auto;
	margin-top:10px;
	margin-bottom:10px;
}
.img-box-7 ul{
	padding:0px 40px;
}
.img-box-7 ul li{
	color:#888;
	border-bottom:1px dashed #e8e8e8;
	line-height:40px;
}
.img-box-7 ul li:last-child{
	border-bottom:0px;
}
/* Image Box #8 Starts */
.img-box-8{
	margin:20px 0px;
}
.img-box-8 .img-box-8-item{
	text-align:center;
	margin:10px auto;
	max-width:250px;
}
.img-box-8 .img-box-8-img{
	position: relative;
	z-index: 10;
	overflow: hidden;
}
.img-box-8 img {
	-webkit-transition: all 2s ease;
	   -moz-transition: all 2s ease;
		-ms-transition: all 2s ease;
		 -o-transition: all 2s ease;
			transition: all 2s ease;
}
.img-box-8 .image-hover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.3);
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;
}
.img-box-8 .img-box-8-item:hover .image-hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.img-box-8  .image-caption {
	margin-top: 30%;
	text-align: center;
}
.img-box-8 .image-caption a i{
	display:inline-block;
	width:35px;
	height:35px;
	border-radius:2px;
	margin:0px 2px;
	line-height:35px;
	color:#fff;
}
.img-box-8 .img-box-8-cap{
	background:#fff;
	padding:12px 20px 15px;
	text-align:center;
	border-top:0px;
}
.img-box-8 h4 a{
	color:#777;
}
.img-box-8 .bor{
	height:3px;
	width:50px;
	margin:10px auto;
}
.img-box-8 p{
	color:#888;
}
.img-box-8 .img-box-8-item:hover .img-box-8-cap{
	-webkit-transition: all 0.5s ease;
	   -moz-transition: all 0.5s ease;
		-ms-transition: all 0.5s ease;
		 -o-transition: all 0.5s ease;
			transition: all 0.5s ease;	
}	
.img-box-8 .img-box-8-item:hover .img-box-8-cap h4 a{
	color:#fff;
}
.img-box-8 .img-box-8-item:hover .img-box-8-cap .bor{
	background:#fff !important;
}
.img-box-8 .img-box-8-item:hover .img-box-8-cap p{
	color:#fff;
}
/* Pricing table #1 */
.pricing-one{
	margin:30px 0px;
}
.pricing-one .pricing-item {
	position: relative;
	max-width: 550px;
	margin: 15px auto;
	padding: 20px;
	border: 1px solid #eee;
	box-shadow:0px 2px 15px rgba(0,0,0,0.05);
	border-radius: 3px;
}
.pricing-one .tag {
	right: -15px;
	top: -15px;
	position: absolute;
	display: block;
	width: 50px;
	height: 50px;
	font-size: 20px;
	line-height: 50px; 
	text-align: center;
	color: #fff;
	border-radius: 50%;
}
.pricing-one  h3 {
	margin-bottom: 20px;
}
.pricing-one .plan-price {
	width: 120px;
	text-align: center;
	float: left;
}
.pricing-one .p-price {
	font-size: 40px;
	font-weight: 600;
	width:100px;
	height:100px;
	display:block;
	margin:10px auto;
	margin-bottom:20px;
	text-align:center;
	line-height:100px;
	color:#fff;
	border-radius:100%;
}
.pricing-one .p-price small {
	font-size: 17px;
}
.pricing-one .plan-details {
	margin-left: 140px;
}
.pricing-one .plan-details ul li {
	padding:8px 5px;
	border-bottom: 1px dashed #eee;
}
.pricing-one .plan-details ul li span {
	font-weight: 600;
	color: #666;
}
@media (max-width: 480px){
	.pricing-one h3 { 
		margin-bottom: 0;
		text-align: center; 
	}
	.pricing-one .plan-price {
		width: 100%;
		float: none;
	}
	.pricing-one .plan-details {
		margin-left: 0px;
		margin-top: 20px;
	}
}
@media (max-width: 767px){
	.pricing-one .pricing-item{ 
		max-width: 400px; 
	}
}
/* Pricing Table #2 */
.pricing-two{
	margin:30px 0px;
}
.pricing-two .table{
	border-top:1px solid #f2f2f2;
}		
.pricing-two .table th,
.pricing-two .table { 
	text-align: center; 
}
.pricing-two .table th,
.pricing-two .table td { 
	padding: 20px 10px; 
	border:1px solid #f2f2f2;
}
.pricing-two .table th { 
	width: 25%;
	font-size: 30px; 
	font-weight: 400;
	border-bottom: 0;
}
.pricing-two .table td:first-child{
	padding-left: 20px;
	text-align: left; 
	padding-top:35px;
}
.pricing-two tr td .ptable-title {
	font-size: 22px;
	font-weight:400;
}
.pricing-two tr td .ptable-title i { 
	width: 23px;
	line-height: 25px;
	text-align: right;
	margin-right: 5px;
}
.pricing-two .ptable-star {
	position: relative;
	display: block;
	text-align: center;
}
.pricing-two .ptable-star i {
	width: 8px;
	font-size: 13px; 
}
.pricing-two .ptable-price {
	display: block;
}
.pricing-two tr td { 
	font-size: 14px; 
	line-height:32px;
}
.pricing-two tr td .ptable-icon {
	display: block;
	margin-bottom: 5px;
	font-size: 30px;
}
/* Pricing Table #3 */
.pricing-three  {
	padding:60px 10px;
	margin:30px 0px;
}
.pricing-three .pricing-content {
	max-width: 900px;
	margin: 20px auto;
	background: #fff;
	box-shadow:0px 0px 10px rgba(0,0,0,0.2);
	border-radius: 6px;
}
.pricing-three .pricing-item {
	padding: 30px 10px;
	text-align: center;
	background: #fff;
	border-radius: 6px;
}
.pricing-three .pricing-item.highlight {
	position: absolute;
	top: -20px;
	z-index: 1000;
	width: 100%;
	padding: 50px 10px;
	box-shadow: 0 0 15px rgba(0,0,0,0.2);
}
.pricing-three h3{
	text-transform: uppercase;
	font-size: 18px;
	margin-bottom:10px;
}
.pricing-three h4 {
	font-size: 55px;
}
.pricing-three h4 span {
	margin-right:5px;
	font-size: 20px;
}
.pricing-three ul {
	margin:20px 0px;
}
.pricing-three ul li {
	padding-top: 7px;
	padding-bottom: 7px;
	font-size: 14px;
}
@media (max-width: 767px){
	.pricing-three .pricing-content {
		max-width: 320px;
		background:transparent;
		box-shadow:none;
	}
	.pricing-three .pricing-item{
		box-shadow:0px 0px 10px rgba(0,0,0,0.2);
	}	
	.pricing-three .pricing-item.highlight {
		position: static;
		margin: 40px 0;
		box-shadow:0px 0px 10px rgba(0,0,0,0.2);
	}
}
/* Pricing Table #4 */
.pricing-four{
	margin:30px 0px;
}
.pricing-four .pricing-item  {
	position: relative;
	max-width: 350px;
	margin: 20px auto;
	padding: 25px 10px;
	border: 1px solid #eee;
	border-radius: 6px;
	text-align: center;
}
.pricing-four .p-price {
	position: relative;
	display: block;
	width: 120px;
	height: 120px;
	line-height: 120px;
	margin: 15px auto;
	text-align: center;
	font-size: 35px;
	font-weight: 600;
	color: #fff;
	border-radius: 50%;
}
.pricing-four .p-price .p-cer {
	font-size: 20px;
	margin-right:3px;
}
.pricing-four .p-price .p-mon {
	font-size: 14px;
}
.pricing-four ul {
	margin: 20px 0;
}
.pricing-four ul li {
	padding:10px 0px;
	padding-left: 45px;
	font-size: 15px;
	text-align: left;
}
.pricing-four ul li i {
	margin-right: 6px;
	width: 20px;
	height: 20px;
	line-height: 20px;
	font-size: 12px;
	text-align: center;
	color:#fff;
	border-radius: 50%;
}
.pricing-four .tag {
	font-size: 16px;
	position: absolute;
	top: -15px;
	right: -15px;
	display: block;
	width: 50px;
	height: 50px;
	line-height: 50px;
	text-align: center;
	color: #fff;
	font-size: 20px;
	border-radius: 50%;	
}
/* Pricing Table #5 */
.pricing-five{
	margin:30px 0px;
}
.pricing-five .pricing-item  {
	position: relative;
	max-width: 350px;
	margin: 20px auto;
	padding-bottom: 20px;
	text-align: center;
	box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
	border-radius: 6px;
}
.pricing-five .p-item-header {
	margin-bottom: 18px;
	padding: 20px 0;
	color: #fff;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
}
.pricing-five .p-item-header h4 {
	margin-bottom:20px;
	color:#fff;
	font-size: 22px;
	font-weight:400;
}
.pricing-five .p-item-header h3 {
	color:#fff;
	font-size: 40px;
}
.pricing-five .p-item-header h3 span {
	position: relative;
	top: -15px;
	left: -2px;
	font-size: 22px;
}
.pricing-five ul li {
	padding:8px 0px;	
	padding-left: 40px;
	font-size: 14px;
	text-align: left;
}
.pricing-five ul li strong {
	color: #444;
	width:50px;
	margin-right:10px;
	display:inline-block;
	text-align:right;
}
/* Pricing Table #6 */
.pricing-six { 
	margin: 30px 0px; 
}
.pricing-six .table tr th { 
	width: 20%; 
	background:#fcfcfc;
}
.pricing-six .table tr th:first-child {
	text-align: right;
	padding-right: 15px;
}
.pricing-six .table tr th,
.pricing-six .table tr td {
	padding:15px;
	font-size: 13px;
}
/* Pricing Table #7 */
.pricing-seven{
	padding:60px 10px;
	margin:30px 0px;
}
.pricing-seven .pricing-item{
	color:#fff;
	margin:10px 0px;
}	
.pricing-seven h3{
	font-weight:400;
	color:#fff;
}
.pricing-seven h4{
	color:rgba(255,255,255,0.7);
	margin:10px 0px;
}
.pricing-seven ul li{
	font-size:16px;
	line-height:35px;
}
/***************/
/* Resume mini */
/***************/
.resume-mini{
	max-width:500px;
	margin:120px auto;
	background:#fff;
	padding:50px 30px;
	border-radius:3px;
	box-shadow:0px 0px 10px rgba(0,0,0,0.3);
}
.resume-mini img{
	width:25px;
	margin:0px auto;
	margin-bottom:20px;
}
.resume-mini h2{
	font-size:25px;
	line-height:40px;
	font-weight:normal;
	margin-bottom:10px;
}
.resume-mini p{
	font-size:15px;
	line-height:35px;
	color:#888;
	margin-bottom:30px;
}
/***************/
/* Team styles */
/***************/
/* Team #1 */
.team-one .team-member {
	max-width:280px;
	margin: 20px auto;
	text-align: center;
}
.team-one img {
	max-width: 280px;
	width: 100%;
	margin: 0 auto;
	margin-bottom:10px;
	border-radius: 3px;
}
.team-one .deg {
	color: #aaa;
}
.team-one .brand-bg{
	margin-top:8px;
}
/* Team #2 */
.team-two .team-member {
	position: relative;
	max-width: 500px;
	margin: 20px 0px;
}
.team-two img {
	width: 100%;
	max-width: 200px;
	float: left;
	border-radius:3px;
}
.team-two .team-details { 
	margin-left: 225px; 
}
.team-two .team-details .deg {
	color: #aaa;
}
.team-two  .brand-bg {
	margin-top:5px;
}
/* Team #3 */
.team-three .team-member {
	max-width: 280px;
	margin: 20px auto;
	text-align:center;
}
.team-three img{
	margin-bottom:10px;
}
.team-three .team-container { 
	position: relative; 
}
.team-three .team-container .team-details {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 7px 15px;
	background: rgba(0,0,0,0.5);
	clear:both;
}
.team-three .team-details h4{
	color:rgba(255,255,255,0.9);
	font-size: 16px;
}
.team-three .brand-bg{
	margin-top:10px;
}
/* Team #4 */
.team-four .team-member {
	max-width: 280px;
	margin: 20px auto;
}
.team-four .img-container { 
	position: relative; 
}
.team-four .img-container .img-hover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 5;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	opacity:0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.3s ease;
	   -moz-transition: all 0.3s ease;
		 -ms-transition: all 0.3s ease;
		  -o-transition: all 0.3s ease;
			  transition: all 0.3s ease;
}
.team-four .team-member:hover .img-container .img-hover {
	opacity:1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.team-four .img-container .brand-bg {
	text-align:center;
	margin-top:120px;
}
.team-four .img-container .brand-bg a i {
	margin: 0 2px;
}
.team-four .team-details {
	padding: 10px 10px;
	text-align: center;
	border:1px solid #eee;
	border-top:0px;
	-webkit-transition: all 0.3s ease;
	   -moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		 -o-transition: all 0.3s ease;
			transition: all 0.3s ease;
}
.team-four .team-details h4 {
	-webkit-transition: all 0.3s ease;
	   -moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		 -o-transition: all 0.3s ease;
			transition: all 0.3s ease;
}
.team-four .team-details .deg,
.team-four .team-details p {
	-webkit-transition: all 0.3s ease;
	   -moz-transition: all 0.3s ease;
		-ms-transition: all 0.3s ease;
		 -o-transition: all 0.3s ease;
			transition: all 0.3s ease;
}
.team-four .team-details .deg {
	display: block;
	font-style: italic;
	color:#aaa;
}
.team-four .team-member:hover .team-details {
	border-top:0px !important;
} 
.team-four .team-member:hover .team-details h4 { 
	color: #fff; 
}
.team-four .team-member:hover .team-details .deg,
.team-four .team-member:hover .team-details p { 
	color: #fff; 
}
/* Team #5 */
.team-five {
	margin:10px auto;
	padding:20px 0px;
}
.team-five .team-member {
	max-width: 300px;
	margin: 20px auto;
	padding: 25px 10px;
	border-radius: 5px;
	text-align: center;
	background: #fff;
	box-shadow:0px 0px 10px rgba(0,0,0,0.1);
}
.team-five .team-member img {
	width: 100%;
	max-width: 170px;
	margin: 0 auto;
	border-radius: 100%;
	box-shadow:inset 0px 0px 5px rgba(0,0,0,0.2);
}
.team-five .team-details {
	margin:10px 0px;
}
.team-five .team-details .deg{
	display: block;
	color:#aaa;
	font-style:italic;
}
.team-five  .team-member .brand-bg { 
	margin-top: 10px; 
}
/* Team #6 */
.team-six .team-member {
	max-width: 280px;
	margin: 20px auto;
	text-align: center;
}
.team-six img {
	width: 100%;
	max-width: 200px;
	margin: 0 auto;
	margin-bottom:10px;
	border-radius: 50%;
}
.team-six .deg{
	color:#aaa;
	font-style:italic;
}
.team-six  .team-links a i {
	width: 25px;
	height: 25px;
	line-height: 25px;
	font-size: 16px;
}
/* Team #7 */
.team-seven .team-member {
	max-width: 280px;
	margin: 20px auto;
	text-align: center;
}
.team-seven .t-container { 
	position: relative;
	z-index: 5;
	border-radius: 4px;
	overflow: hidden;
}
.team-seven .social {
	position: absolute;
	bottom: -70px;
	left: 0;
	z-index: 1;
	width: 100%;
	margin: 0;
	padding: 12px 0;
	background: rgba(0,0,0,0.6);
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	-webkit-transition: all 0.3s ease-out;
	   -moz-transition: all 0.3s ease-out;
		-ms-transition: all 0.3s ease-out;
		 -o-transition: all 0.3s ease-out;
			transition: all 0.3s ease-out;
}
.team-seven .team-member:hover .social {
	bottom: 0;
}
.team-seven img {
	border-radius: 4px;
}
.team-seven h4 {
	margin-top:15px;
}
.team-seven h4 span {
	font-size:14px;
	color:#999;
	font-weight:normal;
}
/* Team #8 */
.team-eight .team-member {
	position: relative;
	max-width: 300px;
	margin: 20px auto;
	text-align: center;
	overflow: hidden;
}
.team-eight .hover-content{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	opacity:0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.35s ease-out;
	   -moz-transition: all 0.35s ease-out;
		-ms-transition: all 0.35s ease-out;
		 -o-transition: all 0.35s ease-out;
			transition: all 0.35s ease-out;
}
.team-eight .team-member:hover .hover-content{
	opacity:1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.team-eight .team-details {
	position: absolute;
	bottom: 30%;
	left: 0;
	width: 100%;
	-webkit-transform: rotate(-360deg);
	   -moz-transform: rotate(-360deg);
		-ms-transform: rotate(-360deg);
		 -o-transform: rotate(-360deg);
			transform: rotate(-360deg);
	-webkit-transition: all 0.35s ease-out;
	   -moz-transition: all 0.35s ease-out;
		-ms-transition: all 0.35s ease-out;
		 -o-transition: all 0.35s ease-out;
			transition: all 0.35s ease-out;
}
.team-eight .team-member:hover .team-details {
	-webkit-transform: rotate(0deg);
	   -moz-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		 -o-transform: rotate(0deg);
			transform: rotate(0deg);
}

.team-eight .team-details h4{
	color: #fff;
}
.team-eight .team-details .deg {
	color: rgba(255,255,255,0.9);
}
.team-eight .team-details  hr {
	margin: 10px auto;
	width: 100px;
	border-bottom:1px solid rgba(255,255,255,0.2);
}
.team-eight .team-details .brand-bg { 
	margin-top: 20px; 
}
/****************/
/* Testimonials */
/****************/
/* Testimonial one */
.testimonials-one{
	margin:10px 0px;
}
.testimonials-one .testimonials-one-item{
	margin:20px 0px;
}
.testimonials-one .testimonials-one-content{
	position: relative;
	background: #fcfcfc;
	border: 1px solid #f8f8f8;
	border-bottom:2px solid #eee;
	padding: 15px 20px 20px 20px;
	margin-bottom:25px;
	border-radius:3px;
}
.testimonials-one .testimonials-one-content p{
	font-size:13px;
	font-style:italic;
	line-height:28px;
	color:#888;
}
.testimonials-one .testimonials-one-content:after, .testimonials-one .testimonials-one-content:before {
	top: 100%;
	right:3%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.testimonials-one .testimonials-one-content:after {
	border-color: rgba(136, 183, 213, 0);
	border-top-color: #eee;
	border-width: 12px;
	margin-left: -31px;
}
.testimonials-one .testimonials-one-content:before {
	border-color: rgba(194, 225, 245, 0);
	border-top-color: #ddd;
	border-width: 12px;
	margin-left: -31px;
}
.testimonials-one h5 {
	font-size:13px;
	text-align:right;
}
.testimonials-one h5  a{
	color:#888;
}
.testimonials-one h5 a img{
	width:40px;
	display:inline;
	border-radius:100px;
	margin-left:5px;
}
/* Testimonial 2 */
.testimonials-two{
	background:url("../../img/raster.png"), url("../../img/backgrounds/2.jpg") center center repeat;
	background-attachment:fixed;
	color:#fff;
	padding:70px 50px;
}
.testimonials-two .testimonials-two-item{
	position:relative;
}
.testimonials-two .testimonials-two-img{
	width:170px;
	float:left;
}
.testimonials-two .testimonials-two-img img{
	max-width:150px;
	border-radius:100px;
	margin:0 auto;
}
.testimonials-two .testimonials-two-content{
	margin-left:200px;
}
@media (max-width:767px){
	.testimonials-two .testimonials-two-img{
		width:auto;
		float:none;
	}
	.testimonials-two .testimonials-two-content{
		margin-left:0px;
		text-align:center;
		margin-top:10px;
	}	
}
.testimonials-two p{
	font-size:16px;
	font-style:italic;
	line-height:35px;
}
.testimonials-two h5{
	font-size:16px;
	color:#fff;
	margin-top:10px;
	font-weight:bold;
}
.testimonials-two h5 span{
	color:#fff;
}
/* Testimonial 3 */
.testimonials-three .testimonials-three-item{
	margin:20px 0px;
	padding:12px 17px;
	border-radius:3px;
	color:#fff;
}
.testimonials-three .testimonials-three-item h5{
	font-size: 13px;
	text-align:right;
	color:#fff;
}
/***********/
/* Gallery */
/***********/
.gallery-three .image-block{
	max-width:600px !important;
}
/***************/
/* Author page */
/***************/
.author-block .about-author img{
	max-width:100px;
	margin-top:7px;
	margin-right:10px;
}
.author-block .author-post-item{
	margin:10px 0 15px 0;
}
.author-block .author-post-item .a-meta{
	background:#fafafa;
	padding:3px 10px;
	margin:5px 0px;
	border-top:1px solid #eee;
	border-bottom:1px solid #eee;
	color:#999;
}
.author-block .author-post-item p{
	color:#777;
}	
.author-block .author-comment-item{
	margin:10px 0px 15px 0px;
}
.author-block .author-comment-item p{
	color:#777;
}	
/********/
/* Blog */
/********/
/* Blog Side Bar */
.sidebar{
	max-width:350px;
}
.sidebar .s-widget{
	margin-bottom:20px;
}
.sidebar .s-widget h5{
	border-bottom:1px solid #eee;
	padding-bottom:10px;
	font-size:13px;
	font-weight:normal;
}
.sidebar .widget-content{
	margin:15px 0px 10px 0px; 
}
.sidebar .widget-content.search{
	max-width:280px;
}
.sidebar .widget-content.categories ul li:before{
	color:#555;
}
.sidebar .widget-content.categories ul li{
	padding-left:5px;
	margin:5px 0px;
}
.sidebar .widget-content.categories ul li a{
	color:#888;
}
.sidebar .widget-content.brand-bg{
	max-width:300px;
}
.sidebar .widget-content.brand-bg a{
	margin-bottom:5px;
	display:inline-block;
}
.sidebar .widget-content.gallery img{
	max-width:90px;
	margin-bottom:5px;
}
.sidebar .widget-content.tabs ul{
	padding:0px;
	margin:0px;
	list-style-type:none;
}
.sidebar .widget-content.tabs .tab-pane ul li{
	margin:7px 0px;
}
.sidebar .widget-content.tabs .tab-pane ul li img{
	max-width:60px;
	float:left;
	margin-right:10px;
	margin-top:3px;
}
.sidebar .widget-content.tabs .tab-pane ul li span{
	color:#555;
}
/* Blog */
.blog-meta{
	margin:10px 0px;
	padding:5px 10px;
	border-top:1px solid #f7f7f7;
	border-bottom:1px solid #f7f7f7;
	background:#fcfcfc;
}
.blog-meta a{
	color:#888;
	font-size:12px;
}
.blog-meta a:hover{
	color:#555;
}
.blog-author .blog-author-img{
	max-width:100px;
	float:left;
	margin-right:15px;
}
/* Blog comments */
.blog-comments .blog-comment-item{
	border-bottom:1px solid #eee;
	padding-bottom:15px;
	margin-bottom:15px;
}
.blog-comments .blog-comment-item.comment-reply{
	margin-left:75px;
}
.blog-comments .comment-author-image{
	float:left;
	max-width:60px;
}
.blog-comments .comment-details{
	margin-left:75px;
}
/* Blog one */
.blog-one .blog-one-item{
	margin-bottom:20px;
	padding-bottom:20px;
	border-bottom:1px solid #eee;
}
.blog-one .blog-one-img{
	max-width:220px;
	float:left;
	margin-bottom:5px;
}
.blog-one .blog-one-img img{
	margin-top:5px;
}
.blog-one .blog-one-content{
	position:relative;
	margin-left:235px;
}
.blog-one .blog-one-content h6{
	color:#aaa;
	font-weight:400;
}
/* Blog two */
.blog-two .blog-two-item{
	margin-bottom:20px;
	padding-bottom:20px;
	border-bottom:1px solid #eee;
}
.blog-two .blog-two-img{
	margin-bottom:10px;
}
.blog-two .blog-two-video{
	margin-bottom:10px;
}
.blog-two .blog-two-content h6{
	color:#aaa;
	font-weight:400;
}
/* Blog three */
.blog-three .blog-three-item{
	margin-bottom:20px;
	padding-bottom:20px;
	border-bottom:1px solid #eee;
}
.blog-three .blog-three-img{
	margin-bottom:10px;
}
.blog-three .blog-three-video{
	margin-bottom:10px;
}
.blog-three .blog-three-content h6{
	color:#aaa;
	font-weight:400;
}
/* Blog four */
.blog-four .blog-four-item{
	padding-bottom:20px;
	margin-bottom:20px;
	border-bottom:1px solid #eee;
}
.blog-four h3{
	font-size:30px;
	line-height:40px;
	margin-bottom:10px;
	font-weight:400;
}
.blog-four h3 a{
	color:#888;
}
.blog-four .meta-block{
	margin:10px 0px;
}
/* Blog masonry */
.blog-masonry .item { 
	padding: 15px;
	width: 33.3%;
}
@media (max-width: 767px){
	.blog-masonry .item { width: 50% !important; }
}
@media (max-width: 480px){
	.blog-masonry .item { width: 100% !important; }
}
.blog-masonry .item .grid-entry{
	overflow: hidden;
	padding-bottom:20px;
	margin-bottom:20px;
	border-bottom:1px solid #eee;
}
.blog-masonry .grid-entry .grid-img{
	position: relative;
	overflow: hidden;
	margin-bottom:20px;
}
.blog-masonry .grid-entry .video-container{
	margin-bottom:20px;
}
.blog-masonry .grid-entry img{
	border-radius: 5px;
	-webkit-transition: all 1s ease-in-out;
	-moz-transition: all 1s ease-in-out;
	-ms-transition: all 1s ease-in-out;
	-o-transition: all 1s ease-in-out;
	transition: all 1s ease-in-out;
}
.blog-masonry .grid-entry .grid-img .grid-img-hover{
	display: block;
	width: 100%;
	height: 0%;
	position: absolute;
	top: 100%;
	left: 0%;
	background: rgba(0,0,0,0.5);
	border-radius:3px;
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-ms-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	z-index: 20;
}
.blog-masonry .grid-entry .grid-img:hover .grid-img-hover{
	top: 0%;
	height:100%;
}
.blog-masonry .grid-entry .grid-img a{
	color: #fff;
}
.blog-masonry .grid-entry .grid-img i.hover-icon{
	position: absolute;
	top: 50%;
	left:50%;
	display: inline-block;
	margin-top: -20px;
	margin-left: -20px;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 14px;
	border-radius: 3px;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	-webkit-transition: all 0.6s ease-in;
	-moz-transition: all 0.6s ease-in;
	-ms-transition: all 0.6s ease-in;
	-o-transition: all 0.6s ease-in;
	transition: all 0.6s ease-in;
	z-index: 25;
}
.blog-masonry .grid-entry .grid-img:hover i.hover-icon{
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.blog-masonry .grid-entry .bor{
	margin-top:6px;
	margin-bottom:5px;
	width:75px;
	height:3px;
}	
.blog-masonry .grid-entry .read-more{
	display:block;
	margin-top:10px;
	color:#444;
	font-weight:bold;
	font-size:12px;
}	
.blog-masonry  .grid-entry .grid-quote{
	color: #fff;
	padding:15px;
}	
.blog-masonry  .grid-entry .grid-quote i{
	margin-bottom:10px;
}
.blog-masonry .grid-entry .grid-quote p{
	font-size:16px;
	line-height:32px;
}
.blog-masonry .grid-entry .grid-quote .author{
	font-size:16px;
	font-weight:600;
}
/****************/
/* Masonry grid */
/****************/
.masonry-grid .item { 
	padding: 15px;
	width: 33.3%;
}
@media (max-width: 767px){
	.masonry-grid .item { width: 50% !important; }
}
@media (max-width: 480px){
	.masonry-grid .item { width: 100% !important; }
}
.masonry-grid .item h4{
	font-size:22px;
	line-height:35px;
	font-weight:400;	
	margin-bottom:5px;
}
.masonry-grid .item h4 i{
	display:inline-block;
	color:#fff;
	width:30px;
	height:30px;
	text-align:center;
	line-height:26px;
	border-radius:100%;
	margin-right:5px;
}
.masonry-grid .item p{
	font-size:15px;
	line-height:33px;
	color:#888;
	margin-left:40px;
}	
.masonry-grid .item p a{
	font-size:13px;
	line-height:23px;
	display:inline-block;
	margin-top:8px;
	font-weight:600;
	color:#555;
}
/************/
/* Magazine */
/************/
.magazine .sidebar{
	max-width:400px;
}
.magazine .sidebar h5{
	font-size:16px !important;
	font-weight:600 !important;
	text-transform:uppercase;
	margin-bottom:5px;
	border-bottom:1px solid #eee;
}
.magazine .sidebar .widget-content.search{
	max-width:300px !important;
}
.magazine .sidebar .widget-content.brand-bg{
	max-width:400px;
}
.magazine .sidebar .widget-content.brand-bg i{
	font-size:20px;
	margin-bottom:4px;
}
.magazine .sidebar .widget-content.gallery img{
	max-width:80px;
}
.magazine .sidebar .widget-content.tags .label{
	background:#f7f7f7;
	color:#888;
}
.magazine .magazine-meta{
	color:#999;
}
.magazine .magazine-meta i{
	margin-right:5px;
}
.magazine .magazine-item{
	margin-bottom:15px;
}
.magazine .magazine-item.mag-1{
	max-width:400px;
}
.magazine .magazine-item.mag-1 img{
	margin-bottom:10px;
}
.magazine h4.mag-head{
	text-transform:uppercase;
	padding-bottom:5px;
	margin-bottom:20px;
	border-bottom:1px solid #eee;
}
.magazine h4.mag-head i{
	margin-right:5px;
}
@media (max-width:767px){
	.magazine .sidebar{
		margin-top:20px;
	}
}
.magazine-list{
	max-width:400px;
}
.magazine-list li{
	margin:20px 0px;	
}
.magazine-list li img{
	max-width:100px;
	float:left;
	margin-top:5px;
}
.magazine-list .m-list-content{
	margin-left:110px;
}
.magazine-list h5{
	font-weight:400;
	line-height:25px;
	margin-top:0px;
	padding-top:0px;
}
.magazine-list .magazine-meta{
	font-size:12px;
}
.magazine-item.mag-2{
	margin:30px 0px;
}
.magazine-item.mag-2 img{
	float:left;
	max-width:200px;
}
.magazine-item.mag-2 .mag-2-content{
	margin-left:220px;
}
@media (max-width:491px){
	.magazine-item.mag-2 img{
		max-width:400px;	
		width:100%;
		float:none !important;
	}
	.magazine-item.mag-2 .mag-2-content{
		margin-left:0px;
		margin-top:10px;
	}
}
.magazine-item.mag-2 h4{
	margin-top:0px;
	padding-top:0px;
	line-height:25px;
}
/* Magazine single */
.magazine-single h2{
	font-size:35px;
	line-height:50px;
}	
@media (max-width:767px){
	.magazine-single h2{
		font-size:22px;
		font-weight:normal;
		line-height:37px;
	}	
}	
.magazine-single .magazine-meta{
	font-size:14px;
	margin:8px 0px;
}	
.magazine-single p{
	font-size:14px;
	line-height:33px;
}	
/* Magazine comments */
.magazine-comments .magazine-comment-item{
	border-bottom:1px solid #eee;
	padding-bottom:15px;
	margin-bottom:15px;
}
.magazine-comments .magazine-comment-item.comment-reply{
	margin-left:75px;
}
.magazine-comments .comment-author-image{
	float:left;
	max-width:60px;
}
.magazine-comments .comment-details{
	margin-left:75px;
}
/***************/
/* Search page */
/***************/
.search-page form{
	margin:10px 0px;
}
.search-page .search-width{
	max-width:350px;
}
.search-page input,
.search-page seelct{
	margin-bottom:5px;
}
/* Search results */
.search-results .s-result-item{
	margin-bottom:15px;
	padding-bottom:15px;
	border-bottom:1px solid #eee;
}
.search-results .s-result-item h5{
	font-size:18px;
}
.search-results .s-result-item .s-links{
	font-weight:600;
}
/*************/
/* Portfolio */
/*************/
/* Index portfolio */
.index-portfolio .carousel{
	max-width:1400px;
	margin:0px auto;
}
.portfolio #filters{
	margin-bottom: 30px;
}
/**** Isotope Filtering ****/
.isotope-item { z-index: 2; }
.isotope-hidden.isotope-item {
	z-index: 1;
}
/**** Isotope CSS3 transitions ****/
.isotope,
.isotope .isotope-item {
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-ms-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
	transition-duration: 0.8s;
}
.isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	-ms-transition-property: height, width;
	-o-transition-property: height, width;
	transition-property: height, width;
}
.isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property:    -moz-transform, opacity;
	-ms-transition-property:     -ms-transform, opacity;
	-o-transition-property:      -o-transform, opacity;
	transition-property:         transform, opacity;
}
/* Portfolio filter one */
.portfolio.pf-one .p-element {
	width: 24%;
	text-align: center;
	overflow: hidden;
	cursor: pointer;
	float: left;
	margin:0.5%;
}
.portfolio.pf-one .p-element p{
	margin-bottom:10px;
}
@media (max-width:1050px){
	.portfolio.pf-one .p-element {
		width: 32%;
	}
}
@media (max-width:991px){
	.portfolio.pf-one .p-element {
		width: 48%;
	}
}
@media (max-width:480px){
	.portfolio.pf-one .p-element {
		width: 100%;
		margin-right:0px;
		margin-left:0px;
	}
}
/* Portfolio filter two */
.portfolio.pf-two .p-element {
	width: 24.9%;
	text-align: center;
	overflow: hidden;
	cursor: pointer;
	float: left;
}
.portfolio.pf-two .p-element p{
	margin-bottom:10px;
}
@media (max-width:1050px){
	.portfolio.pf-two .p-element {
		width: 33.333%;
	}
}
@media (max-width:991px){
	.portfolio.pf-two .p-element {
		width: 50%;
	}
}
@media (max-width:480px){
	.portfolio.pf-two .p-element {
		width: 100%;
	}
}
/************/
/* Projects */
/************/
.projects .related-projects img{
	max-width:150px;
	margin-right:8px;
}
/**************/
/* Blockquote */
/**************/
/* Quote one */
.quote-one .quote-one-item{
	margin:10px auto;
}
.quote-one span{
	float:left;
	font-family: 'Georgia Times New Roman', serif;
	width:60px;
	display:inline-block;
	line-height:115px;
	font-size:120px;
	text-align:center;
	color:#fff;
}
.quote-one .quote-one-right{
	margin-left:80px;
}
.quote-one p{
	font-size:20px;
	line-height:40px;
	font-weight:300;
}
/* Quote two */
.quote-two{
	background:url("../../img/raster.png"),url("../../img/backgrounds/3.jpg") center center;
	background-attachment:fixed;
}
.quote-two .quote-two-content{
	text-align:center;
	max-width:900px;
	margin:60px auto;
	color:#fff;
}
.quote-two span{ 
	font-family: 'Georgia Times New Roman', serif;
	font-size:120px;
	color:#fff;
	width:90px;
	height:90px;
	border-radius:100px;
	line-height:140px;
	display:block;
	margin:10px auto;
}
.quote-two h3{
	font-size:35px;
	line-height:50px;
	font-weight:300;
	color:#fff;
	margin-bottom:10px;
}
@media (max-width:767px){
	.quote-two h3{
		font-size:25px;
		line-height:40px;
	}
}
/* Quote three */
.quote-three .quote-three-content{
	text-align:center;
	max-width:900px;
	margin:30px auto;
}

.quote-three img{ 
	width:100px;
	height:100px;
	display:block;
	margin:10px auto;
	border-radius:100px;
}
.quote-three h3{
	font-size:22px;
	line-height:40px;
	font-weight:400;
	max-width:800px;
	margin:0 auto;
}
/***********/
/* Clients */
/***********/
/* Client One */
.client-one{
	margin:10px 0px;
}
.client-one .c1-item{
	position:relative;
	margin:10px;
	box-shadow:0px 0px 1px rgba(0,0,0,0.2);
	padding:5px;
}
.client-one .c1-item  .img-hover{
	position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
	cursor:pointer;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   filter:alpha(opacity=0);
   -webkit-transition: all 0.4s ease-out;
   -moz-transition: all 0.4s ease-out;
   -o-transition: all 0.4s ease-out;
   -ms-transition: all 0.4s ease-out;
   transition: all 0.4s ease-out;
	z-index:999;
}
.client-one .c1-item:hover .img-hover{
	opacity: 1;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
   filter:alpha(opacity=100);	
}
.client-one .c1-item h5{
	position: absolute;
	top:40%;
	text-align:center;
	font-size:18px;
	font-weight:bold;
	text-transform:uppercase;
	width:100%;
	display: inline-block;
	opacity: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
   filter:alpha(opacity=0);
	-webkit-transition: all 0.4s ease-in;
	-moz-transition: all 0.4s ease-in;
	-ms-transition: all 0.4s ease-in;
	-o-transition: all 0.4s ease-in;
	transition: all 0.4s ease-in;
	z-index: 10000;
}
.client-one .c1-item:hover h5{
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
   filter:alpha(opacity=100);
}
.client-one .c1-item h5 a{
	color:#fff;
}
/* Client Two */
.client-two{
	margin:10px 0px;
}
.client-two .c2-item{
	box-shadow:inset 0px 0px 100px rgba(0,0,0,0.03);
	border:1px solid #e5e5e5;
	position:relative;
	margin:10px auto;
	text-align:center;
	height:150px;
}
@media (max-width:767px){
	.client-two .c2-item{
		max-width:250px;
	}
}
.client-two .c2-item a img{
	width:100%;
	max-width:200px;
	padding:45px 0px;
	display:inline-block;
}
/* Client three */
.client-three{
	margin:10px 0px;
}

.client-three .c3-item{
	margin:10px;
}

.client-three .c3-item a img{
	width:100%;
	max-width:260px;
	margin:0 auto;
}
/**************/
/* Contact us */
/**************/
/* Contact us one */
.contact-us-one .contact-map iframe{
	width:100%;
	height:300px;
	border:0px;
}
.contact-us-one h5{
	margin:5px 0px;
}
.contact-us-one .contact-item{
	margin-bottom:10px;
}
.contact-us-one .contact-item i{
	color:#fff;
}
.contact-us-one ul li{
	margin:10px 0px;
}
.contact-us-one ul li a{
	color:#888;
}
/* Contact us two */
.contact-us-two h5{
	margin:5px 0px;
}
.contact-us-two .contact-map iframe{
	width:100%;
	height:270px;
	padding:3px;
	border:1px solid #ddd;
}
.contact-us-two .contact-item{
	max-width:300px;
	margin:10px auto;
	padding:15px 10px;
	color:#fff;
	border-radius:3px;
	box-shadow:inset 0px 3px 0px rgba(0,0,0,0.2);
}
.contact-us-two i{
	width:40px;
	height:40px;
	border-radius:100px;
	line-height:40px;
	display:block;
	background:rgba(0,0,0,0.2);
	margin:10px auto;
}
.contact-us-two h4,
.contact-us-two a,
.contact-us-two a:hover{
	color:#fff;
}
/* Contact us three */
.contact-us-three .contact-map iframe{
	width:100%;
	height:270px;
	padding:3px;
	border:1px solid #ddd;
}
/* Contact us four */
.contact-us-four .contact-map iframe{
	width:100%;
	height:300px;
	border:0px;
}
/*************/
/* Error Log */
/*************/
.error-log{
	background: #fcfcfc;
	padding: 10px;
	border: 1px solid #eee;
	height: 400px;
	overflow: auto;
	font-family: "Courier New", monospace;
}
.error-log ul{
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}
/***********/
/* Counter */
/***********/
/* Counter #1 */
.counter-one{
	margin:20px 0px;
}
.counter-one .counter-item {
	max-width: 200px;
	margin: 10px auto;
}
.counter-one  i {
	display: block;
	margin-bottom: 20px;
	font-size: 25px;
}
.counter-one  .number-count {
	display: inline-block;
	font-size: 42px;
	color: #666;
}
.counter-one hr {
	width: 35px;
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 10px;
	border-width: 3px;
	border-bottom: 0;
	border-left: 0;
	border-right: 0;
}
.counter-one h5{
	color: #999;
	text-transform:uppercase;
}
/* Counter #2 */
.counter-two{
	padding:40px 0px;
}
.counter-two .counter-item {
	max-width: 200px;
	margin: 10px auto;
}
.counter-two  .num-container {
	width: 120px;
	height: 120px;
	line-height: 120px;
	margin: 0 auto;
	margin-bottom: 20px;
	font-size: 40px;
	color: #555;
	background: #fff;
	border-radius: 50%;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	-ms-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}
.counter-two .counter-item:hover  .num-container {
	box-shadow: 0 0 0 10px rgba(0,0,0,0.15);
}
.counter-two  .num-container .per{
	display: inline-block;
	margin-left: 2px;
	font-size: 25px;
}
.counter-two .counter-item h4 {
	color: #fff;
}
/* Counter #3 */
.counter-three{
	margin:20px 0px;
}
.counter-three .counter-item {
	max-width: 200px;
	margin: 10px auto;
}
.counter-three .num-container {
	font-size: 90px;
	line-height:90px;
	font-weight: 600;
}
.counter-three  .num-container .per{
	left: 2px;
	font-size:25px;
	font-weight: 400;
}
/* Counter #4 */
.counter-four{
	margin:20px 0px;
}
.counter-four .counter-item {
	max-width: 360px;
	margin: 10px auto;
}
.counter-four i {
	width: 75px;
	height: 75px;
	line-height: 73px;
	font-size: 35px;
	text-align: center;
	border-radius: 50%;
	float: left;
	border:1px solid #f3f3f3;
}
.counter-four h4,
.counter-four p { margin-left: 90px; }
.counter-four h4 {
	position: relative;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 40px;
	line-height: 50px;
}
.counter-four h4 small { 
	display: inline-block;
	margin-left: 3px;
	font-size: 18px; 
	font-weight:600;
	text-transform: uppercase;
	color:#bbb;
}
.counter-four p {
	color:#888;
}
/* knob #1 */
.knob-one{
	margin:20px 0px;
}
.knob-one .knob-item{
	max-width: 300px;
	margin: 10px auto;
}
.knob-one .knob-item h4 {
	text-transform: uppercase;
}
/* knob #2 */
.knob-two{
	margin:20px 0px;
}
.knob-two .knob-item{
	max-width: 300px;
	margin: 10px auto;
}
.knob-two .knob-item h4{
	text-transform: uppercase;
}
/* Components */
.components img{
	max-width:140px;
	display:inline-block;
}
/* Icons page */
.fa-icons ul{
	list-style-type:none;
	padding:0px;
	margin:0px;
}
.fa-icons ul li{
	margin:5px 0px;
}
.fa-icons ul li i{
	margin-right:5px;
}
/* Events */
.events .event-details{
	padding:5px 10px;
	background:#fcfcfc;
	border:1px solid #eee;
	margin-bottom:5px;
	color:#777;
}
.events .event-details i{
	margin-right:5px;
}
/* Events two */
.events-two .event-item{
	padding:12px 10px;
	background:#fff;
	border:1px solid #ddd;
	box-shadow:0px 0px 5px rgba(0,0,0,0.1);
	border-radius:3px;
	margin:20px 0px;
}
.events-two .e-details{
	float:left;
	width:150px;
	text-align:center;
	font-weight:600;
	font-size:14px;
	margin:5px 0px;
}
.events-two .e-details span{
	display:block;
	font-size:16px;
}
.events-two .e-details .btn{
	margin-top:10px;
}
/* Horizontal timeline */
.h-timeline-row{
	margin: 50px 0px;
	padding-bottom: 30px; 
	border-bottom: 1px solid #e8e8e8; 
}
.h-timeline-row .entry {
	position: relative;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-left:1px solid #eee !important;
	border-right:1px solid #eee !important;
	border-bottom:1px solid #eee !important;
}
.h-timeline-row .entry:after, 
.h-timeline-row .entry:before {
	position: absolute;
	top: 100%;
	left: 39px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	pointer-events: none;
}
.h-timeline-row .entry:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #fff;
	border-width: 8px;
	margin-left: 1px;
}
.h-timeline-row .entry:before {
	border-color: rgba(0, 0, 0, 0);
	border-top-color: #ccc;
	border-width: 9px;
	margin-left: 0px;
}
.h-timeline-row .entry .entry-content { 
	padding: 15px; 
}
.h-timeline-row .entry .meta { 
	font-size: 13px; 
}
.h-timeline-row .entry .entry-content h4 {
	text-transform: capitalize;
}
.h-timeline-row .entry .entry-icon {
	position: absolute;
	left: 30px;
	bottom: -50px;
	width: 35px;
	height: 35px;
	font-size: 14px;
	line-height: 30px;
	text-align: center;
	color: #fff;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0 2px rgba(0,0,0,0.15);
}
@media (max-width: 991px){
	.h-timeline-row {
		margin-left:15px;
		padding-bottom: 0; 
		padding-left: 30px;
		border-bottom: 0; 
		padding-top: 0;
		border-left: 1px solid #e8e8e8;
	}
	.h-timeline-row .entry  { margin-bottom: 30px; }
	.h-timeline-row .entry .entry-icon {
		position: absolute;
		left: -48px;
		bottom: 100%;
		margin-bottom: -45px;
	}
	.h-timeline-row .entry:after, .h-timeline-row .entry:before {
		top: 20px;
		left: -16px;
	}
	.h-timeline-row .entry:after {
		border-top-color: transparent;
		border-right-color: #fff;
		border-width: 8px;
		margin-left: 0px;
		margin-top: 0;
	}
	.h-timeline-row .entry:before {
		border-top-color: transparent;
		border-right-color: #ccc;
		border-width: 9px;
		margin-left: -2px;
		margin-top: -1px;;
	}
}
/* Vertical timeline */
.vertical-timeline{
	margin:20px 15px;
}
.v-timeline-col {
	padding-left: 30px;
	padding-right:25px;
	border-left: 1px solid #e8e8e8;
}
.v-timeline-col .entry {
	position: relative;
	margin-bottom: 30px;
	padding-bottom: 15px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
}
.v-timeline-col .entry:after, .v-timeline-col .entry:before {
	top: 47px;
	right: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.v-timeline-col .entry:after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 7px;
	margin-right: -1px;
	margin-top: -7px;
}
.v-timeline-col .entry:before {
	border-color: rgba(0, 0, 0, 0);
	border-right-color: #ccc;
	border-width: 8px;
	margin-right: -1px;
	margin-top: -8px;
}
.v-timeline-col .entry-icon {
	position: absolute;
	left: -51px;
	top: 27px;
	width: 40px;
	height: 40px;
	line-height: 35px;
	text-align: center;
	color: #fff;
	font-size: 16px;
	border-radius: 50%;
	border: 2px solid #fff;
	box-shadow: 0 0 2px rgba(0,0,0,0.1);
}
.v-timeline-col .entry-head {
	padding: 6px 15px;
	color: #fff;
	font-weight:600;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.v-timeline-col .entry-content {
	margin-top: 15px;
	padding: 0 15px;
}
.v-timeline-col .entry-content img{
	margin:5px 0px;
}
.v-timeline-col .entry-content ul{
	padding-left:20px;
}
.v-timeline-col .entry-content .gallery img { 
	max-width:125px;
	margin-right:10px;
	margin-top:6px;
	margin-bottom:6px;
}
.v-timeline-col .entry-content blockquote{
	border-color:#eee;
	background:transparent;
	padding:5px 0px;
	padding-left:20px;
}
.v-timeline-col .team .team-member img {
	width: 100%;
	max-width: 130px;
	float: left;
}
.v-timeline-col .team .team-member h5,
.v-timeline-col .team .team-member p ,
.v-timeline-col .team .team-member .brand-bg { 
	margin-left: 145px; 
}
.v-timeline-col .entry-content.video .video-container{
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
	margin-top:5px;
}
.v-timeline-col .entry-content.video .video-container iframe{
	width: 100%;
	height: 100%;
	border: 0;
	position: absolute;
	top:0;
	left:0;
}
/* Body background image for some pages */
body.bg-img{
	background:url("../../img/raster.png"),url("../../img/backgrounds/1.jpg");
	background-size:cover;
	background-attachment:fixed;
}
/* 404 Error */
.error-inside  form{
	max-width:250px;
	margin:20px auto;
}
.error {
	max-width:600px;
	margin:0 auto;
	margin-top:100px;
	background:#fff;
	text-align:center;
	box-shadow:0px 0px 10px rgba(0,0,0,0.2);
	border-radius:5px;
	padding:50px;
}
.error  h2{
	font-size:50px;
	line-height:65px;
}
@media (max-width:767px){
	.error{
		max-width:500px;
	}
	.error h2{
		font-size:35px;
		line-height:50px;
	}
}
.error  h3{
	color:#888;
}
.error  p{
	max-width:400px;
	margin:0 auto;
	margin-top:10px;
}
.error  form{
	max-width:250px;
	margin:20px auto;
}
.error  form input{
	color:#888;
}
.error  ul li a{
	color:#aaa;
}
.error ul li a:hover{
	color:#888;
}
/* Maintanance Starts */
.maintenance{
	background:#fff;
	text-align:center;
	max-width:800px;
	margin:0 auto;
	margin-top:100px;
	padding:50px;
	border:1px solid #ddd;
	border-radius:5px;
	position:relative;
	box-shadow:0px 0px 10px rgba(0,0,0,0.1);
}
.maintenance a i{
	width:60px;
	height:60px;
	line-height:60px;
	border-radius:100px;
	text-align:center;
	color:#fff;
	font-size:28px;
	position:absolute;
	top:-30px;
	left:50%;
	margin-left:-30px;
}
.maintenance h2{
	font-size:50px;
	line-height:65px;
}
@media (max-width:767px){
	.maintenance h2{
		font-size:40px;
		line-height:55px;
	}
}
.maintenance h4{
	margin-bottom:20px;
}
/* Login-Register */
.login-reg{
	max-width:450px;
	margin:0 auto;
	margin-top:100px;
	padding:30px;
	background:#fff;
	box-shadow:0px 0px 10px rgba(0, 0, 0, 0.2);
	border-radius:5px;
}
.login-reg h4{
	text-align:center;
}
.login-reg .s-media{
	text-align:center;
}
.login-reg .s-media a.btn{
	margin:0px 5px;
}
/* Inside login and register form */
.login-reg-form form{
	max-width:400px;
}
@media (max-width:991px){
	.login-reg-form{
		max-width:500px;
	}
}
/* Combined Form */
.combined-form{
	max-width:400px;
	margin:0 auto;
	margin-top:60px;
	box-shadow:0px 3px 10px rgba(0,0,0,0.2);
}
.combined-form .nav-tabs{
	border-bottom:0px;
	text-align:center;
}
.combined-form ul li a i{
	display:block;
	font-size:25px;
	margin-bottom:8px;
	text-align:center;
}
.combined-form ul li a{
	background:#fff;
	color:#ccc;
	border:0px;
	font-size:17px;
	text-transform:uppercase;
	font-weight:600;
	border-right:1px solid #e5e5e5;
	border-top:3px solid #e5e5e5; 
	border-bottom:1px solid #e5e5e5;
	border-radius:0px !important;
   -webkit-transition: all 0.7s ease;
   -moz-transition: all 0.7s ease;
   -o-transition: all 0.7s ease;
   -ms-transition: all 0.7s ease;
   transition: all 0.7s ease;  
}
.combined-form ul li a:hover{
	color:#bbb;
	border-top:3px solid #ddd !important; 
}
.combined-form ul li:first-child a{
	border-top-left-radius:5px !important;
	border-left:1px solid #e5e5e5;
}
.combined-form ul li:first-child.active a, .combined-form ul li:first-child.active:hover a{
	border-left:1px solid #e5e5e5 !important;
}
.combined-form ul li:last-child a{
	border-top-right-radius:5px !important;
	border-right:1px solid #e5e5e5 !important;
}
.combined-form ul li:last-child.active a, .combined-form ul li:last-child.active:hover a{
	border-right:1px solid #e5e5e5 !important;
}
.combined-form ul li.link-one.active a , .combined-form ul li.link-one.active:hover a{
	border-top:3px solid #51d466 !important;
}
.combined-form ul li.link-one.active a i{
	color:#51d466;
}
.combined-form ul li.link-two.active a, .combined-form ul li.link-two.active:hover a{
	border-top:3px solid #32c8de !important;
}
.combined-form ul li.link-two.active a i{
	color:#32c8de;
}
.combined-form ul li.link-three.active a, .combined-form ul li.link-three.active:hover a{
	border-top:3px solid #cb79e6 !important;
}
.combined-form ul li.link-three.active a i{
	color:#cb79e6;
}
.combined-form ul li.active a{
	color:#444 !important;
}
.combined-form ul li.active a, .combined-form ul li.active:hover a{
	background:#fff !important;
	border-left:0px !important;
}
.combined-form ul li:hover a{
	background:#fff !important;
	border-bottom:1px solid #e5e5e5;
	border-right:1px solid #e5e5e5;
}
.combined-form-content .tab-content{
	background:#fff;
	padding:20px 30px 10px;
	border:1px solid #e5e5e5;
	border-top:0px;
	border-bottom-right-radius:5px;
	border-bottom-left-radius:5px;
}
.combined-form-content .tab-content h4{
	text-align:center;
	color:#777;
}
.combined-form-content .tab-content .bor{
	width:50px;
	height:3px;
	margin:0 auto;
	margin-top:10px;
	margin-bottom:20px;
	border-radius:2px;
}
.combined-form-content .tab-content label{
	color:#666;
	font-size:13px;
	font-weight:400;
}	
@media (max-width:767px){
	.nav-tabs.nav-justified li a{
		margin-bottom:0px;
	}
}
/* Scroll to top */
.totop {
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 104400;
	display:none;
}
.totop a i{
	display: block;
	width: 32px;
	height: 32px;
	line-height: 30px;
	text-align: center;
	font-size: 20px;
	border-radius: 2px;
}
.totop a, .totop a:visited{
	color: #fff;
}
.totop a:hover {
	color: #fff;
	text-decoration: none;
}
/* Foot */
.foot {
	border-top: 3px solid;
	margin-top: 30px;
	padding: 15px 0px;
	background: #333333;
	color: #aaa;
}
.foot .foot-item {
	margin: 15px auto;
	max-width: 500px;
}
@media (max-width: 991px) {
	.foot .foot-item {
		margin-bottom: 35px;
	}
}
.foot .foot-item h5 {
	color: #ccc;
	font-size: 13px;
	line-height: 0px;
	margin-bottom: 20px;
}
.foot .foot-item p {
	margin: 0px;
}
.foot .foot-item a {
	color: #bbb;
}
.foot .foot-item a:hover {
	color: #ccc;
}
.foot .foot-item .brand-bg {
	margin: 10px 0px 20px 0px;
}
.foot .foot-item .subscribe-box {
	margin-top: 20px;
}
.foot .foot-item .subscribe-box .input-group {
	max-width: 250px;
	margin-top: 20px;
}
.foot .foot-item ul li {
	border-bottom: 1px solid rgba(255, 255, 255, 0.05);
	padding: 5px 0px;
}
.foot .foot-item ul li:last-child {
	border-bottom: 0px;
}
.foot .foot-item ul li :before {
	line-height: 30px;
	font-family: FontAwesome;
	content: "\f105";
	font-size: 13px;
	margin-right: 7px;
	font-weight: normal;
	color: #ccc;
}
.foot .foot-item .foot-recent-img a img {
	max-width: 75px;
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 10px;
}
.foot .foot-item .foot-recent-img a img.img-thumbnail {
	padding: 1px;
	border-radius: 2px;
}
.foot .foot-item .address h6 {
	color: #ccc;
	font-size: 13px;
}	
.foot .foot-item .address h6 i {
	font-size: 14px;
}
.foot .foot-item .address p i {
	font-size: 14px;
	margin-right: 8px;
	color: #ccc;
}
.foot .foot-item .address p.add {
	margin-left: 20px;
}
.foot .foot-item .address p.tel {
	line-height: 35px;
}
/* Footer */
footer {
	padding: 15px 10px 4px;
	border-top: 1px solid rgba(255, 255, 255, 0.05);
	font-size: 12px;
	background: #333333;
	color: #ccc;
}
footer a {
	color: #ccc;
}
footer a:hover {
	color: #aaa;
}
@media (max-width: 767px) {
	footer .pull-left,
	footer .pull-right {
		float: none !important;
		text-align: center;
	}
}
/* UI designs */
.ui-designs img{
	margin:15px auto;
	max-width:350px;
	width:100%;
	box-shadow:inset 0px 0px 5px rgba(0,0,0,0.1);
}