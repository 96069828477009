/* Your CSS codes here */
.logo img {
    width: 100%;
    height: auto;
}

.img-container img {
    width: auto;
    height: 140px;
}
