/*************/
/* Ecommerce */
/*************/
/* Ecommerce carousel */
.ecommerce .carousel{
	max-width:1400px;
	margin:0px auto;
}
.ecommerce .table td,
.ecommerce .table th{ 
	padding:10px;
}
.ecommerce .table-responsive{
	border:0px;
}
/* Hot tags */
.ecommerce .hot-tag{
	top: 5px;
	right: 5px;
	position: absolute;
	z-index: 20;
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	color: #fff;
	border-radius: 100px;
	font-size: 12px;
	border: 0;
	border-top-right-radius: 15px;
}
/* Sidebar Nav */
.ecommerce .sidebar{
	max-width:300px;
}
.ecommerce .sidebar h2 {
	position: relative;
	padding: 12px 20px;
	background:#fafafa;
	font-size: 14px;
	line-height: 19px;
	color:#999;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border:1px solid #eee;
}
.ecommerce .sidebar .navi,
.ecommerce .sidebar .account-nav{
	list-style-type: none;
	padding: 0px;
	margin:0;
	margin-bottom: 30px;
	background: #fff;
	border-top: 0;
	margin-top:-2px;
}
.ecommerce .sidebar .navi > li,
.ecommerce .sidebar .account-nav > li{
	list-style-type:none;
	padding: 8px 40px;
	border: 1px solid #eee;
	border-top:0px;
}
.ecommerce .sidebar .navi li a,
.ecommerce .sidebar .account-nav li a{
	color: #898989;
}
.ecommerce .sidebar .navi > li:last-child,
.ecommerce .sidebar .account-nav > li:last-child{
	border-bottom-left-radius:4px;
	border-bottom-right-radius:4px;
}
.ecommerce .sidebar .navi > li:before,
.ecommerce .sidebar .account-nav > li:before{
	font-family: 'FontAwesome';
	float: left;
	margin-left: -20px;
	content: "\f101";
	color:#aaa;
}
.ecommerce .sidebar .navi > li:hover,
.ecommerce .sidebar .account-nav > li:hover { 
	background: #fdfdfd; 
}
.ecommerce .sidebar ul.navi  li  ul{
	display: none;
	list-style-type: none;
	padding: 0;
	margin: 0;
}
.ecommerce .sidebar .navi > li > ul > li > a {
	font-size: 13px;
	font-weight: normal;
	padding: 2px 12px;
	color: #999;
	display: block;
	text-decoration: none;
}
.ecommerce .sidebar .navi > li > ul > li > a:before{
	font-family: 'FontAwesome';
	float: left;
	margin-left: -12px;
	content: "\f105";
	color:#aaa;	
	position:relative;
	top:2px;
}
.ecommerce .sidebar .navi a .arrow {
	display: inline-block;
	vertical-align: middle;
	margin-top: 5px;
}
.ecommerce .sidebar .my-list{
	padding:0px;
	margin:0px;
	border:1px solid #eee;
	margin-bottom:10px;
	margin-top:-2px;
	border-top:0px;
	padding:10px 20px;
}
.ecommerce .sidebar .my-list a,
.ecommerce .sidebar .my-list a:hover{
	color:#777;
}
.ecommerce .sidebar .my-list li{
	margin:5px 0px;
}
/* Sidebar widget */
.ecommerce .sidebar .s-widget {
	margin: 15px auto;
	background: #fff; 
	border-radius: 4px;
	border: 1px solid #eee;
	padding:15px;
}
.ecommerce .sidebar .s-widget h3 {
	font-size:18px;
	line-height:23px;
	padding-bottom:10px;
	margin-bottom:10px;
	border-bottom:1px dashed #eee;
}
.ecommerce .sidebar .sw-content{
	margin:20px 0px 0px 0px;
}
.ecommerce .sidebar .sw-content img {
	max-height: 80px;
	float: left;
	text-align:center;
}
.ecommerce .sidebar .sw-content h4,
.ecommerce .sidebar .sw-content h5,
.ecommerce .sidebar .sw-content .sw-price {
	margin-left: 60px;
}
.ecommerce .sidebar .sw-content h4{
	font-size: 16px;
	line-height: 21px;
	position:relative;
	top:5px;
	margin-bottom:5px;
}
.ecommerce .sidebar .sw-content  .star { 
	display: block; 
	margin-left: 60px; 
	font-size: 12px; 
	line-height: 20px;
	margin-bottom:5px;
}
.ecommerce .sidebar .sw-content .sw-price {
	display: block;
	font-size: 16px;
	line-height: 21px;
	color:#888;
	font-weight:600;
}
/* Recent Items */
.ecommerce .recent-items .owl-content{
	border:1px solid #e7e7e7;
	border-radius:4px;
	box-shadow:0px 0px 6px rgba(0,0,0,0.05);
}
.ecommerce .recent-items .owl-content img{
	max-height:220px;
	margin:0px auto;	
	margin-bottom:10px;
}
.ecommerce .recent-items .owl-content h4{
	font-size:16px;
}
/* Shopping wishlist */
.ecommerce .shopping-wishlist table img{
	max-height:60px;
}
/* Shopping View Cart Page */
.shopping-vcart table td img { 
	max-height: 50px; 
}
.shopping-vcart .input-group { 
	max-width: 110px; 
}/* Information Block CSS */
.ecommerce .info { 
	margin: 20px 0px; 
}
.ecommerce .info .info-item {
	max-width: 350px;
	margin: 10px auto;
	padding: 15px;
	color: #fff;
	border-radius: 3px;
}
.ecommerce .info .info-item .num-outer {
	display: inline-block;
	padding: 7px;
	background :rgba(255,255,255,0.35); 
	border-radius: 100px;
	float: left;
}
.ecommerce .info .info-item i {
	display: inline-block;
	width: 40px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	font-size: 18px;
	background: #fff;
	border-radius: 100px;
}
.ecommerce .info .info-item h4,
.ecommerce .info .info-item h5 { 
	margin-left: 63px; 
}
.ecommerce .info .info-item h4 {
	font-size: 22px;
	font-weight:400;
	line-height: 26px;
	color: #fff;
}
.ecommerce .info .info-item h5{
	margin-top: 6px;
	font-size: 13px;
	line-height: 14px;
	color: #fff;
	font-weight:400;
}
/* Shopping Items CSS */
.ecommerce .shopping-item { 
	position: relative;
	max-width: 280px;
	margin: 10px auto;
	padding: 15px;
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	box-shadow: 0 0 3px rgba(0,0,0,0.05);
	overflow: hidden;
	-webkit-transition: all 0.4s ease-in-out;
	   -moz-transition: all 0.4s ease-in-out;
      	-ms-transition: all 0.4s ease-in-out;
	     -o-transition: all 0.4s ease-in-out;
        	transition: all 0.4s ease-in-out;
}
.ecommerce .shopping-item img {
	max-height: 200px; 
	margin: 10px auto;
}
.ecommerce .shopping-item .item-hover { 
	position: absolute;
	left: -110px;
	bottom: 40%;
	width: 110px;
	height: 35px;
	line-height: 35px;
	-webkit-transition: all 0.4s ease-in-out;
	   -moz-transition: all 0.4s ease-in-out;
      	-ms-transition: all 0.4s ease-in-out;
	     -o-transition: all 0.4s ease-in-out;
        	transition: all 0.4s ease-in-out;
}
.ecommerce .shopping-item:hover .item-hover { 
	left: 0px; 
}
.ecommerce .shopping-item .item-hover a{
	color:#fff;
	margin-left:15px;
	text-transform:uppercase;
}
.ecommerce .shopping-item a.btn { 
	margin-top: 10px; 
	margin-bottom: 10px; 
}
/* Single Items Page */
.ecommerce .shopping-single .carousel img{
	max-height:350px;
	width:auto !important;
	margin:0px auto;
}
.ecommerce .shopping-single .item-review{
	margin:10px 0px;
	background:#fdfdfd;
	padding:10px 13px;
	border:1px solid #eee;
}
.ecommerce .shopping-single h5{
	margin-bottom:8px;
}
.ecommerce .shopping-single .form .input-group,
.ecommerce .shopping-single .form .form-control { 
	max-width: 130px; 
}
/* Compare Page */
.item-compare .table thead td { 
	text-align: center; 
}
.item-compare .table thead td img {
	max-height: 100px;
	margin: 10px auto;
}
.item-compare .table tbody td { 
	width: 26%; 
}
.item-compare .table thead td:first-child,
.item-compare .table tbody th:first-child{ 
	width: 200px; 
}
.item-compare .table tbody th { 
	background: #fcfcfc; 
}
/* Ecommerce UI */
.ecom-ui{
	position: relative;
	max-width: 280px;
	margin: 20px auto;
	padding: 15px 20px;
	border: 1px solid #ddd;
	background: #fff;
	border-radius:5px;
}
.ecom-ui img {
	max-height: 220px;
	margin: 0 auto;
	margin-bottom:15px;
	margin-top:5px;
}
.ecom-ui p{
	color:#888;
}
.ecom-ui .ecom-hot{
	position: absolute;
	top: -15px;
	right: -15px;
	z-index: 1000;
	font-size: 13px;
	color: #fff;
	text-align: center;
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
}
.ecom-ui hr{
	margin:8px 0px;
}
/* Product item #1 */
.ecom-ui-one .price {
	font-size: 20px;
	font-weight: 600;
}
.ecom-ui-one .cart a{
	font-size:14px;
	border-bottom:1px dotted #777;
	color:#666;
}
.ecom-ui-one .cart a:hover{
	border-bottom:0px;
	color:#999;
}
/* Product item #2 */
.ecom-ui-two .cart a{
	font-size:14px;
	border-bottom:1px dotted #777;
	color:#666;
}
.ecom-ui-two .cart a:hover{
	border-bottom:0px;
	color:#999;
}
.ecom-ui-two .p-media i {
	margin-left:5px;
	font-size: 14px;
}
/* Product item #3 */
.ecom-ui-three .btn {
	margin-top:10px;
}
.ecom-ui-three .p-media i {
	margin-top: 10px;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 14px;
	border: 1px solid #eee;
	border-radius: 50%;
	-webkit-transition: all 0.35s ease-in;
	-moz-transition: all 0.35s ease-in;
	-ms-transition: all 0.35s ease-in;
	-o-transition: all 0.35s ease-in;
	transition: all 0.35s ease-in;
}
.ecom-ui-three .p-media a:hover i { 
	background:#fafafa;
}
/* Product item #4 */
.ecom-ui-four {
	text-align: center;
	position: relative; 
	overflow: hidden;
	border-width:2px;
}
.ecom-ui-four .ecom-hot {
	position: absolute;
	top: 6px;
	right: 6px;
	z-index: 1000;
	color: #fff;
	text-align: center;
	width: 45px;
	height: 45px;
	line-height: 45px;
	border-radius: 60px;
	border-top-right-radius: 15px !important;
}
.ecom-ui-four .img-container{
	position:relative;
}
.ecom-ui-four h4{
	line-height:40px;
}
.ecom-ui-four .price {
	display: inline-block;
	font-size: 25px;
	font-weight: 400;
}
.ecom-ui-four .price.price-old {
	font-size: 16px;
	color:#aaa;
	text-decoration: line-through;
}
.ecom-ui-four .ecom {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	padding: 8px 0;
	-webkit-transition: all 0.35s ease-in;
	-moz-transition: all 0.35s ease-in;
	-ms-transition: all 0.35s ease-in;
	-o-transition: all 0.35s ease-in;
	transition: all 0.35s ease-in;
}
.ecom-ui-four:hover .ecom {
	margin-top: -47px;
}
.ecom-ui-four .ecom .btn{
	border:0px;
}
.ecom-ui-four .ecom .btn:hover{
	background:#fff;
}
/* Product item #5 */
.ecom-ui-five { 
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
}
.ecom-ui-five .ecom-hot {
	top: 15px;
	left: 0px;
	text-align: center;
	width:50px;
	line-height:28px;
	height:auto;
	border-radius:0px;
	border-top-right-radius:3px;
	border-bottom-right-radius:3px;
}
.ecom-ui-five h4 {
	padding:10px 15px;
}
.ecom-ui-five .product-details {
	padding: 8px 15px;
}
.ecom-ui-five .product-details .price {
	font-size: 18px;
	font-weight: 600;
	color: #fff;
}
.ecom-ui-five .product-details a.ecom {
	font-size: 14px;
	line-height:25px;
	color: #fff;
	border-bottom: 1px dotted #fff;
}
.ecom-ui-five .product-details a.ecom:hover{
	border-bottom:0px;
}
.ecom-ui-five .p-media a {
	display: block;
	float: left;
	width: 33.33%;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	text-align: center;
	margin: 0 auto;
	color:#888;
}
.ecom-ui-five .p-media a:nth-child(3) { 
	border-right: 0; 
}
/* Product item #6 */
.ecom-ui-six { 
	position: relative;
	overflow: hidden;
	border-width:2px;
}
.ecom-ui-six .ecom-hot {
	position: absolute;
	top: 6px;
	right: 6px;
	z-index: 1000;
	color: #fff;
	text-align: center;
	width: 45px;
	height: 45px;
	line-height: 45px;
	border-radius: 60px;
	border-top-right-radius: 15px !important;
}
.ecom-ui-six .ecom {
	position: absolute;
	top: 30%;
	left: -150px;
	display: block;
	-webkit-transition: all 0.35s ease-in;
	-moz-transition: all 0.35s ease-in;
	-ms-transition: all 0.35s ease-in;
	-o-transition: all 0.35s ease-in;
	transition: all 0.35s ease-in;
}
.ecom-ui-six:hover .ecom {
	left: 0;
}
.ecom-ui-six .ecom a{
	display:inline-block;
	z-index: 5;
	padding: 7px 30px;
	font-size: 16px;
	color: #fff;
	font-weight:600;
}